import React from 'react'
import Box from '../components/Box/Box'
import { Formik } from 'formik'
import * as Yup from 'yup'
import BoxBody from '../components/Box/BoxBody'
import { Button } from 'reactstrap'
import FormikInput from '../components/Form/FormikInput'
import { withRouter } from 'react-router'
import MinimalLayout from '../components/Layout/MinimalLayout'
import { ENDPOINT_COMPANY_CREATE } from '../config'

class CompanyCreate extends React.Component {
  handleSubmit = (form) => {
    fetch(ENDPOINT_COMPANY_CREATE, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(form)
    })
      .then(res => res.json())
      .then(res => {
        console.log(res)
        this.nextPage(res._id)
      })
  }

  nextPage = (companyId) => {
    this.props.history.push(`/${companyId}/keyUser/create`)
  }

  render() {
    return (
      <MinimalLayout>
        <Box
          title="Crea tu empresa"
          subtext="Ahora, debes seguir el proceso de configuración de la cuenta en unos simples pasos."
        >
          <BoxBody>
            <Formik
              onSubmit={this.handleSubmit}
              validationSchema={Yup.object().shape({
                businessName: Yup.string().required('Required'),
                rut: Yup.string().required('Required'),
                category: Yup.string().required('Required'),
                address: Yup.string().required('Required'),
                name: Yup.string().required('Required'),
                mainUnit: Yup.string().required('Required'),
              })}
            >
              {props =>
                <form onSubmit={props.handleSubmit}>
                  <FormikInput formikProps={props} label="Razón Social" name="businessName"/>
                  <FormikInput formikProps={props} label="RUT" name="rut"/>
                  <FormikInput formikProps={props} label="Giro" name="category"/>
                  <FormikInput formikProps={props} label="Dirección" name="address"/>
                  <FormikInput formikProps={props} label="Nombre Fantasía" name="name"/>
                  <FormikInput formikProps={props} label="Unidad Principal" name="mainUnit"/>

                  <div className="text-center mt-5">
                    <Button type="submit" disabled={props.isSubmitting}>Siguiente</Button>
                  </div>
                </form>
              }
            </Formik>
          </BoxBody>
        </Box>
      </MinimalLayout>
    )
  }
}

export default withRouter(CompanyCreate)
