import { ENDPOINT_COURSES } from '../config'

export const importCourses = (token, file) => {
  let formData = new FormData()
  formData.append('file', file)

  return fetch(`${ENDPOINT_COURSES}/import`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    },
    body: formData
  }).then(res => res.json())
}
