import React from 'react'
import Box from '../components/Box/Box'
import MinimalLayout from '../components/Layout/MinimalLayout'
import * as Yup from 'yup'
import FormikInput from '../components/Form/FormikInput'
import { Button } from 'reactstrap'
import { Formik } from 'formik'
import BoxBody from '../components/Box/BoxBody'
import { ENDPOINT_USERS } from '../config'
import { UserContext } from '../contexts'
import { toast } from 'react-toastify'
import logo from '../assets/logo2.png'
import safety from '../assets/poweredBySafety.svg'
import queryString from 'query-string'

const centrar = {
  textAlign: 'center',
}

const logoBhp = {
  width: '180px',
  marginBottom: '15px'
}

const logoSafety = {
  width: '130px',
  marginTop: '50px'
}

const title = {
  color: '#909399',
  textAlign: 'center',
  fontSize: '22px'
}

const box = {
  backgroundColor: 'white',
  padding: '30px',
  boxShadow: 'none'
}

export default class Login extends React.Component {
  static contextType = UserContext

  componentDidMount () {
    const query = queryString.parse(this.props.location.search)

    if (query.token) {
      this.handleSuccess({
        token: query.token,
      })
    }
  }

  handleSubmit = (form, { setSubmitting }) => {
    fetch(`${ENDPOINT_USERS}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(form)
    })
      .then(res => res.json())
      .then(res => {
        setSubmitting(false)

        if (res.errors) {
          toast.error(res.message)
        } else {
          this.handleSuccess(res)
        }
      })
  }

  handleSuccess = user => {
    this.context.setToken(user.token)
    this.props.history.push(`/users/companies`)
  }

  render () {
    return (
      <div className="wallpaper">
        <MinimalLayout>
          <Box style={box}>
            <div style={{
              ...centrar,
              backgroundColor: '#001d41',
              marginBottom: 16,
            }}>
              <img style={logoBhp} src={logo} alt="Logo"/>
            </div>

            <h3 style={title}>Gestión de usuarios</h3>
            <BoxBody>
              <Formik
                onSubmit={this.handleSubmit}
                validationSchema={Yup.object().shape({
                  rut: Yup.string().required('Required'),
                  password: Yup.string().required('Required')
                })}
              >
                {props => (
                  <form onSubmit={props.handleSubmit}>
                    <FormikInput formikProps={props} label="RUT" name="rut"/>
                    <FormikInput
                      formikProps={props}
                      label="Contraseña"
                      type="password"
                      name="password"
                    />

                    <div className="text-center mt-5">
                      <Button type="submit" disabled={props.isSubmitting}>
                        Iniciar sesión
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
              <div style={centrar}>
                <img style={logoSafety} src={safety} alt="Logo"/>
              </div>
            </BoxBody>
          </Box>
        </MinimalLayout>
      </div>
    )
  }
}
