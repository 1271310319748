import * as Yup from 'yup'

export const fields = [
  { label: 'Nombres', name: 'firstNames' },
  { label: 'Apellidos', name: 'lastNames' },
  { label: 'RUT', name: 'rut' },
  { label: 'Celular', name: 'phone' },
  { label: 'Fecha de nacimiento', name: 'birthDate', type: 'date' },
  {
    label: 'Género', name: 'gender', options: [
      { value: 'Female', display: 'Femenino' },
      { value: 'Male', display: 'Masculino' },
      { value: 'Other', display: 'Otro' }
    ]
  },
  { label: 'Nacionalidad', name: 'nationality' },
]

export const validation = Yup.object().shape({
  firstNames: Yup.string().required('Requerido'),
  lastNames: Yup.string().required('Requerido'),
  rut: Yup.string().required('Requerido'),
  birthDate: Yup.string().required('Requerido'),
  phone: Yup.string().required('Requerido'),
  gender: Yup.string().required('Requerido'),
  nationality: Yup.string().required('Requerido'),
})

export const validationCompany = Yup.object().shape({
  firstNames: Yup.string().required('Requerido'),
  lastNames: Yup.string().required('Requerido'),
  rut: Yup.string().required('Requerido'),
  birthDate: Yup.string().required('Requerido'),
  email: Yup.string().required('Requerido'),
  position: Yup.string(),
  phone: Yup.string().required('Requerido'),
  gender: Yup.string().required('Requerido'),
  nationality: Yup.string().required('Requerido'),
})
