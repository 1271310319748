import { ENDPOINT_COMPANIES } from '../config'

export const fetchPositions = (token, companyId) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/positions`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  })
    .then(res => res.json())
}

export const createPosition = (token, companyId, values) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/positions`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(values)
  })
    .then(res => res.json())
}

export const updatePosition = (token, companyId, positionId, values) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/positions/${positionId}`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(values)
  })
    .then(res => res.json())
}
