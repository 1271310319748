import React from 'react'
import { Button, Col, Row } from 'reactstrap'
import { Formik } from 'formik'
import ButtonNavigation from '../components/ButtonNavigation/ButtonNavigation'
import FormikInput from '../components/Form/FormikInput'
import { UserContext } from '../contexts'
import DashboardLayout from '../components/Layout/DashboardLayout'
import { toast } from 'react-toastify'
import { fetchCompany, fetchIsAdmin, updateCompany, updateCompanyPhoto } from '../requests/companies'
import * as companyForm from '../forms/companyForm'
import { companyMassiveImport } from '../requests/companies'

class CompanyDetail extends React.Component {
  static contextType = UserContext

  state = {
    company: {},
    canAdmin: false,
  }

  importFileRef = React.createRef()

  componentDidMount () {
    this.refreshCompany()
    this.refreshCanAdmin()
  }

  refreshCanAdmin = () => {
    const { companyId } = this.props.match.params
    const { token, bundle } = this.context

    if (bundle.isAdmin === true) {
      this.setState({ canAdmin: bundle.isAdmin })
    } else {
      fetchIsAdmin(token, companyId, bundle._id)
        .then(canAdmin => this.setState({ canAdmin }))
    }
  }

  refreshCompany = () => {
    const { token } = this.context
    const { companyId } = this.props.match.params

    fetchCompany(token, companyId).then(res => {
      this.setState({ company: res })
    })
  }

  handleSubmit = (form, { setSubmitting }) => {
    const { companyId } = this.props.match.params

    updateCompany(this.context.token, companyId, form)
      .then(res => {
        setSubmitting(false)
        this.setState({ company: res })
        toast.success('Se guardaron los cambios')
      })
  }

  handleFileChange = (e) => {
    if (!e.target.files) {
      return
    }

    let file = e.target.files[0]
    this.setState({ file })
  }

  handleSubmitPhoto = () => {
    const { file } = this.state
    const { token } = this.context
    const { companyId } = this.props.match.params

    updateCompanyPhoto(token, companyId, file)
      .then(res => {
        this.refreshCompany()
      })
  }

  handleImport = () => {
    this.importFileRef.current.click()
  }

  handleImportChange = (e) => {
    this.uploadFile(e.target.files[0])
  }

  uploadFile = (file) => {
    const { token } = this.context
    const { companyId } = this.props.match.params

    companyMassiveImport(token, companyId, file)
      .then(res => {
        this.refreshCompany()
        toast.success('Se completó la importación masiva')
      })
  }

  render () {
    const { token } = this.context
    const { canAdmin, company } = this.state

    return (
      <DashboardLayout companyId={this.props.match.params.companyId} token={token} heading="Datos de la Empresa">
        {this.renderNavigation()}

        <Row className="mt-3">
          <Col md={3}>
            <img src={`/core/${company.photo}`} alt="" width="100%"/>
            {
              canAdmin ?
                <React.Fragment>
                  <hr/>
                  <p/>
                  <input type="file" onChange={this.handleFileChange} accept="image/x-png,image/gif,image/jpeg"/>
                  <Button color="secondary" size="sm" onClick={this.handleSubmitPhoto} block
                          className="mt-2">Editar</Button>

                  <form action="">
                    <input type="file" hidden ref={this.importFileRef} onChange={this.handleImportChange}
                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"/>
                    <Button color="secondary" size="sm" onClick={this.handleImport} block
                            className="mt-1 mb-4">Incorporación Masiva</Button>
                  </form>
                </React.Fragment>
                : null
            }
          </Col>

          <Col md={9}>
            <Formik
              initialValues={this.state.company}
              enableReinitialize={true}
              onSubmit={this.handleSubmit}
              validationSchema={companyForm.validation}
            >
              {props =>
                <form onSubmit={props.handleSubmit}>
                  <FormikInput label="Rut" name="rut" formikProps={props} disabled/>
                  <FormikInput label="Razón Social" name="businessName" formikProps={props} disabled/>
                  <FormikInput label="Giro" name="category" formikProps={props} disabled={!canAdmin}/>
                  <FormikInput label="Dirección" name="address" formikProps={props} disabled={!canAdmin}/>
                  <FormikInput label="Nombre Fantasía" name="name" formikProps={props} disabled={!canAdmin}/>
                  <FormikInput label="País" name="country" formikProps={props} disabled={!canAdmin} type="select">
                    {countries.map(country =>
                      <option key={country} value={country}>{country}</option>
                    )}
                  </FormikInput>
                  <FormikInput label="Ciudad" name="city" formikProps={props} disabled={!canAdmin}/>
                  <FormikInput label="Planta" name="plant" formikProps={props} disabled={!canAdmin}/>
                  <FormikInput label="Unidad Principal" name="mainUnit" formikProps={props} disabled={!canAdmin}/>

                  <div className="text-center mt-5">
                    <Button type="submit" disabled={props.isSubmitting}>Guardar cambios</Button>
                  </div>
                </form>
              }
            </Formik>
          </Col>
        </Row>
      </DashboardLayout>
    )
  }

  renderNavigation () {
    const { companyId } = this.props.match.params
    const { location } = this.props

    return (
      <ButtonNavigation links={[
        { to: `/companies/${companyId}/detail`, text: 'Datos Empresa' },
      ]} location={location}/>
    )
  }
}

const countries = [
  'Afganistán',
  'Albania',
  'Alemania',
  'Andorra',
  'Angola',
  'Antigua y Barbuda',
  'Arabia Saudita',
  'Argelia',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaiyán',
  'Bahamas',
  'Bangladés',
  'Barbados',
  'Baréin',
  'Bélgica',
  'Belice',
  'Benín',
  'Bielorrusia',
  'Birmania',
  'Bolivia',
  'Bosnia y Herzegovina',
  'Botsuana',
  'Brasil',
  'Brunéi',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Bután',
  'Cabo Verde',
  'Camboya',
  'Camerún',
  'Canadá',
  'Catar',
  'Chad',
  'Chile',
  'China',
  'Chipre',
  'Ciudad del Vaticano',
  'Colombia',
  'Comoras',
  'Corea del Norte',
  'Corea del Sur',
  'Costa de Marfil',
  'Costa Rica',
  'Croacia',
  'Cuba',
  'Dinamarca',
  'Dominica',
  'Ecuador',
  'Egipto',
  'El Salvador',
  'Emiratos Árabes Unidos',
  'Eritrea',
  'Eslovaquia',
  'Eslovenia',
  'España',
  'Estados Unidos',
  'Estonia',
  'Etiopía',
  'Filipinas',
  'Finlandia',
  'Fiyi',
  'Francia',
  'Gabón',
  'Gambia',
  'Georgia',
  'Ghana',
  'Granada',
  'Grecia',
  'Guatemala',
  'Guyana',
  'Guinea',
  'Guinea-Bisáu',
  'Guinea Ecuatorial',
  'Haití',
  'Honduras',
  'Hungría',
  'India',
  'Indonesia',
  'Irak',
  'Irán',
  'Irlanda',
  'Islandia',
  'Islas Marshall',
  'Islas Salomón',
  'Israel',
  'Italia',
  'Jamaica',
  'Japón',
  'Jordania',
  'Kazajistán',
  'Kenia',
  'Kirguistán',
  'Kiribati',
  'Kuwait',
  'Laos',
  'Lesoto',
  'Letonia',
  'Líbano',
  'Liberia',
  'Libia',
  'Liechtenstein',
  'Lituania',
  'Luxemburgo',
  'Madagascar',
  'Malasia',
  'Malaui',
  'Maldivas',
  'Malí',
  'Malta',
  'Marruecos',
  'Mauricio',
  'Mauritania',
  'México',
  'Micronesia',
  'Moldavia',
  'Mónaco',
  'Mongolia',
  'Montenegro',
  'Mozambique',
  'Namibia',
  'Nauru',
  'Nepal',
  'Nicaragua',
  'Níger',
  'Nigeria',
  'Noruega',
  'Nueva Zelanda',
  'Omán',
  'Países Bajos',
  'Pakistán',
  'Palaos',
  'Panamá',
  'Papúa Nueva Guinea',
  'Paraguay',
  'Perú',
  'Polonia',
  'Portugal',
  'Reino Unido de Gran Bretaña e Irlanda del Norte',
  'República Centroafricana',
  'República Checa',
  'República de Macedonia',
  'República del Congo',
  'República Democrática del Congo',
  'República Dominicana',
  'República Sudafricana',
  'Ruanda',
  'Rumanía',
  'Rusia',
  'Samoa',
  'San Cristóbal y Nieves',
  'San Marino',
  'San Vicente y las Granadinas',
  'Santa Lucía',
  'Santo Tomé y Príncipe',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leona',
  'Singapur',
  'Siria',
  'Somalia',
  'Sri Lanka',
  'Suazilandia',
  'Sudán',
  'Sudán del Sur',
  'Suecia',
  'Suiza',
  'Surinam',
  'Tailandia',
  'Tanzania',
  'Tayikistán',
  'Timor Oriental',
  'Togo',
  'Tonga',
  'Trinidad y Tobago',
  'Túnez',
  'Turkmenistán',
  'Turquía',
  'Tuvalu',
  'Ucrania',
  'Uganda',
  'Uruguay',
  'Uzbekistán',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Yibuti',
  'Zambia',
  'Zimbabue',
]

export default CompanyDetail
