let prefix = ''

// prefix = 'https://usuarios.trazo.dev'

export const ENDPOINT_COMPANY_CREATE = prefix + '/core/companies'
export const ENDPOINT_COMPANIES = prefix + '/core/companies'
export const ENDPOINT_USERS = prefix + '/core/users'
export const ENDPOINT_COURSES = prefix + '/core/courses'
export const ENDPOINT_PERMISSIONS = prefix + '/permissions'

export const ENDPOINT_DOCUMENT_DEFINITIONS = prefix + '/documents/definitions'
export const ENDPOINT_DOCUMENT_SUBMISSIONS = prefix + '/documents/submissions'
export const ENDPOINT_SII = prefix + '/sii'

/*
export const ENDPOINT_COMPANY_CREATE = 'http://localhost:4000/companies'
export const ENDPOINT_COMPANIES = 'http://localhost:4000/companies'
export const ENDPOINT_USERS = 'http://localhost:4000/users'
export const ENDPOINT_DOCUMENT_DEFINITIONS = 'http://localhost:5000/definitions'
export const ENDPOINT_DOCUMENT_SUBMISSIONS = 'http://localhost:5000/submissions'
*/
