import { ENDPOINT_PERMISSIONS } from '../config'

export const fetchUserPermissions = (token, userId, companyId, moduleName) => {
  return fetch(`${ENDPOINT_PERMISSIONS}?companyId=${companyId}&userId=${userId}&moduleName=${moduleName}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  }).then(res => res.json())
}

export const updateUserPermissions = (token, userId, companyId, moduleName, permissions) => {
  return fetch(`${ENDPOINT_PERMISSIONS}?companyId=${companyId}&userId=${userId}&moduleName=${moduleName}`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(permissions)
  })
    .then(res => res.json())
}
