import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Button } from 'reactstrap'
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap'
import { Formik } from 'formik'
import FormikInputGenerator from '../components/Form/FormikInputGenerator'
import * as companyForm from '../forms/companyForm'

import DashboardLayout from '../components/Layout/DashboardLayout'
import { addCompanyVendor, createCompany, fetchCompanyByRut, fetchCompanyVendors } from '../requests/companies'
import ListTable from '../components/ListTable/ListTable'
import FormikInput from '../components/Form/FormikInput'
import { UserContext } from '../contexts'
import { toast } from 'react-toastify'
import { fetchSiiRecord } from '../requests/sii'

class CompanyVendors extends React.Component {
  static contextType = UserContext

  static propTypes = {
    companyId: PropTypes.string.isRequired,
  }

  state = {
    formModalOpen: false,
    createMode: 'search',
    vendor: {},
    vendors: []
  }

  componentDidMount () {
    this.refreshVendors()
  }

  refreshVendors = () => {
    const { companyId } = this.props
    const { token } = this.context

    fetchCompanyVendors(token, companyId)
      .then(vendors => this.setState({ vendors }))
  }

  handleSearchSubmit = (form, { setSubmitting }) => {
    const { token } = this.context
    const { rut } = form

    fetchCompanyByRut(token, rut)
      .then(vendor => {
        if (vendor.errors) {
          fetchSiiRecord(rut)
            .then(result => {
              if (result.error) {
                toast.error('No encontramos la empresa en el Servicio de Impuestos Internos.')

                this.setState({
                  createMode: 'create',
                })
              } else {
                toast.warn('Empresa no encontrada, rellene el formulario para ingresarla.')

                const form = { businessName: result.business_name, rut: result.rut }

                this.setState({
                  createMode: 'create',
                  vendor: form
                })
              }
            })

          setSubmitting(false)
        } else {
          this.handleCompanyFound(vendor, setSubmitting)
        }
      })
  }

  handleCompanyFound = (vendor, setSubmitting) => {
    const { token } = this.context
    const { companyId } = this.props

    toast.success('Empresa encontrada, realizaremos el enlace automáticamente...')

    addCompanyVendor(token, companyId, vendor._id)
      .then(this.handleResult('Empresa enlazada con éxito', setSubmitting))
  }

  handleCreateSubmit = (form, { setSubmitting }) => {
    const { companyId } = this.props
    const { token } = this.context

    createCompany(token, form)
      .then(vendor => {
        if (vendor.errors) {
          toast.error(vendor.message)
        } else {
          toast.success('Empresa creada con éxito, realizaremos el enlace...')

          addCompanyVendor(token, companyId, vendor._id)
            .then(this.handleResult('Empresa enlazada con éxito', setSubmitting))
        }
      })
  }

  handleResult = (message, setSubmitting) => (res) => {
    setSubmitting(false)
    this.toggleFormModal()

    if (res.errors) {
      toast.error(res.message)
    } else {
      this.refreshVendors()
      toast.success(message)
    }
  }

  toggleFormModal = () => {
    this.setState({ formModalOpen: !this.state.formModalOpen })
  }

  render () {
    const { companyId } = this.props
    const { vendors } = this.state
    const { token } = this.context

    return (
      <DashboardLayout companyId={companyId} heading="Empresas Contratistas" token={token}>
        <Button onClick={this.toggleFormModal} size="sm">+ Agregar empresa contratista</Button>
        <p/>

        <ListTable data={vendors} columns={[
          { key: 'name', display: 'Empresa' },
          { key: 'rut', display: 'RUT' },
          { key: 'category', display: 'Giro' },
          { key: 'address', display: 'Dirección' },
        ]}/>

        {this.renderFormModal()}
      </DashboardLayout>
    )
  }

  renderFormModal () {
    const { createMode } = this.state

    if (createMode === 'search') {
      return this._renderSearchFormModal()
    } else if (createMode === 'create') {
      return this._renderCreateFormModal()
    }
  }

  _renderSearchFormModal () {
    const { formModalOpen, vendor } = this.state

    return (
      <Modal isOpen={formModalOpen} toggle={this.toggleFormModal}>
        <ModalHeader toggle={this.toggleFormModal}>Empresa contratista</ModalHeader>

        <Formik
          onSubmit={this.handleSearchSubmit}
          initialValues={vendor}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            rut: Yup.string().required('Requerido'),
          })}
        >
          {props =>
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <p>
                  <strong>Primero busque la empresa por su RUT</strong>. En caso de encontrarla podremos enlazarla sin
                  ingresar más
                  datos, de lo contrario deberá ingresarla en el sistema.
                </p>

                <FormikInput formikProps={props} label="RUT" name="rut"/>
              </ModalBody>

              <ModalFooter>
                <Button color="danger" onClick={this.toggleFormModal}>Cerrar</Button>
                {' '}
                <Button color="success" type="submit" disabled={props.isSubmitting}>Buscar</Button>
              </ModalFooter>
            </form>
          }
        </Formik>
      </Modal>
    )
  }

  _renderCreateFormModal () {
    const { formModalOpen, vendor } = this.state

    return (
      <Modal isOpen={formModalOpen} toggle={this.toggleFormModal}>
        <ModalHeader toggle={this.toggleFormModal}>Empresa contratista</ModalHeader>

        <Formik
          onSubmit={this.handleCreateSubmit}
          initialValues={vendor}
          enableReinitialize={true}
          validationSchema={companyForm.validation}
        >
          {props =>
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <FormikInputGenerator fields={companyForm.fields} formikProps={props}/>
              </ModalBody>

              <ModalFooter>
                <Button color="danger" onClick={this.toggleFormModal}>Cerrar</Button>
                {' '}
                <Button color="success" type="submit" disabled={props.isSubmitting}>Buscar</Button>
              </ModalFooter>
            </form>
          }
        </Formik>
      </Modal>
    )
  }
}

export default class Wrapper extends React.Component {
  render () {
    const { companyId } = this.props.match.params

    return (
      <CompanyVendors {...this.props} companyId={companyId}/>
    )
  }
}
