import React from 'react'
import PropTypes from 'prop-types'
import FormikInput from './FormikInput'

export default class FormikInputGenerator extends React.Component {
  static propTypes = {
    fields: PropTypes.any.isRequired,
    formikProps: PropTypes.any.isRequired,
  }

  render () {
    const { fields } = this.props
    return fields.map(this.renderField)
  }

  renderField = (field, index) => {
    if (field.options) {
      return this.renderSelectField(field, index)
    }

    const { formikProps } = this.props
    const type = field.type || 'text'

    return <FormikInput key={index} formikProps={formikProps} label={field.label} name={field.name} type={type}/>
  }

  renderSelectField = (field, index) => {
    const { formikProps } = this.props
    return (
      <FormikInput key={index} formikProps={formikProps} label={field.label} name={field.name} type="select">
        <option value="" disabled>Seleccione</option>
        {field.options.map((option, index) => <option key={index} value={option.value}>{option.display}</option>)}
      </FormikInput>
    )
  }
}
