import queryString from 'query-string'
import React from 'react'
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import FormikInput from '../components/Form/FormikInput'
import { Button, Col, Row } from 'reactstrap'
import DashboardLayout from '../components/Layout/DashboardLayout'
import { toast } from 'react-toastify'
import UserNavigation from '../components/UserNavigation/UserNavigation'
import { UserContext } from '../contexts'
import PropTypes from 'prop-types'
import { addCompanyAdmin, deleteCompanyAdmin, fetchCompanyUser, fetchIsAdmin } from '../requests/companies'
import { fetchPositions } from '../requests/positions'
import { createUser, updateCompanyUser, updateUserPhoto } from '../requests/users'

class UserForm extends React.Component {
  static contextType = UserContext

  static propTypes = {
    companyId: PropTypes.string,
    userId: PropTypes.string,
  }

  state = {
    user: {},
    positions: [],
    isKeyUser: false,
    canAdmin: false,
    file: ''
  }

  componentDidMount () {
    this.refreshUser()
    this.refreshPositions()
    this.refreshIsKeyUser()
    this.refreshCanAdmin()
  }

  refreshPositions = () => {
    const { companyId } = this.props
    const { token } = this.context

    fetchPositions(token, companyId)
      .then(positions => this.setState({ positions }))
  }

  refreshUser = () => {
    const { userId, companyId } = this.props
    const { token } = this.context

    fetchCompanyUser(token, companyId, userId).then(user => this.setState({ user }))
  }

  refreshIsKeyUser = () => {
    const { userId, companyId } = this.props
    const { token } = this.context

    fetchIsAdmin(token, companyId, userId)
      .then(isKeyUser => this.setState({ isKeyUser }))
  }

  refreshCanAdmin = () => {
    const { companyId } = this.props
    const { token, bundle } = this.context

    if (bundle.isAdmin === true) {
      this.setState({ canAdmin: bundle.isAdmin })
    } else {
      fetchIsAdmin(token, companyId, bundle._id)
        .then(canAdmin => this.setState({ canAdmin }))
    }
  }

  handleFileChange = (e) => {
    if (!e.target.files) {
      return
    }

    let file = e.target.files[0]
    this.setState({ file })
  }

  handleSubmitPhoto = () => {
    const { file } = this.state
    const { token } = this.context
    const { userId } = this.props

    updateUserPhoto(token, userId, file)
      .then(res => {
        this.refreshUser()
      })
  }

  handleSubmit = (form, { setSubmitting }) => {
    const { userId, companyId } = this.props
    const { token } = this.context

    if (userId) {
      // update an existing user.
      updateCompanyUser(token, companyId, userId, form)
        .then(res => {
          setSubmitting(false)

          if (this.handleIfError(res)) {
            this.refreshUser()
            toast.success('Usuario actualizado')
          }
        })
    } else {
      // create a new user.
      // TODO: deprecated code.
      createUser(token, form)
        .then(res => {
          setSubmitting(false)

          if (this.handleIfError(res)) {
            toast.success('Usuario creado, serás redireccionado a la lista de usuarios...', {
              onClose: () => this.props.history.push('/users')
            })
          }
        })
    }
  }

  handleIfError = (res) => {
    if (res.errors) {
      toast.error(res.message)
      return false
    } else {
      return true
    }
  }

  toggleIsAdmin = () => {
    const { isKeyUser } = this.state
    const { token } = this.context
    const { userId, companyId } = this.props

    if (isKeyUser) {
      deleteCompanyAdmin(token, companyId, userId).then(this.refreshIsKeyUser)
    } else {
      addCompanyAdmin(token, companyId, userId).then(this.refreshIsKeyUser)
    }
  }

  render () {
    const { positions, canAdmin, user } = this.state
    const { token, bundle } = this.context
    const { userId, companyId, location } = this.props

    return (
      <DashboardLayout heading="Trabajador" companyId={this.props.companyId} token={token}>
        <UserNavigation canAdmin={canAdmin} companyId={companyId} userId={userId} location={location}/>
        <p/>

        <Row>
          <Col md={3}>
            <img src={`/core/${user.photo}`} alt="" width="100%"/>
            {
              (canAdmin || (bundle._id === userId)) ?
                <React.Fragment>
                  <hr/>
                  <p/>
                  <input type="file" onChange={this.handleFileChange} accept="image/x-png,image/gif,image/jpeg"/>
                  <Button color="secondary" size="sm" onClick={this.handleSubmitPhoto} block
                          className="mt-2 mb-4">Editar</Button>
                </React.Fragment>
                : null
            }

            <hr/>

            {this.renderAdminToggle()}
            {this.renderChangePassword()}
          </Col>

          <Col>
            <Formik
              onSubmit={this.handleSubmit}
              enableReinitialize={true}
              initialValues={this.state.user}
              validationSchema={Yup.object().shape({
                firstNames: Yup.string().required('Required'),
                lastNames: Yup.string().required('Required'),
                rut: Yup.string().required('Required'),
                phone: Yup.string().required('Required'),
                email: Yup.string().required('Required'),
                birthDate: Yup.string(),
                gender: Yup.string().required('Required'),
                nationality: Yup.string().required('Required'),
                password: Yup.string()
              })}
            >
              {props =>
                <form onSubmit={props.handleSubmit}>
                  <FormikInput formikProps={props} label="Nombres" name="firstNames" disabled={!canAdmin}/>
                  <FormikInput formikProps={props} label="Apellidos" name="lastNames" disabled={!canAdmin}/>
                  <FormikInput formikProps={props} label="RUT" name="rut" disabled={!canAdmin}/>
                  <FormikInput formikProps={props} label="Teléfono" name="phone" disabled={!canAdmin}/>
                  <FormikInput formikProps={props} label="Nacionalidad" name="nationality" disabled={!canAdmin}/>
                  <FormikInput formikProps={props} label="Fecha de nacimiento" name="birthDate" type="date"
                               disabled={!canAdmin}/>

                  <FormikInput formikProps={props} label="Genero" name="gender" type="select" disabled={!canAdmin}>
                    <option value="Female">Femenino</option>
                    <option value="Male">Masculino</option>
                    <option value="Other">Otro</option>
                  </FormikInput>

                  <FormikInput formikProps={props} label="Correo electrónico" name="email" disabled={!canAdmin}/>

                  <FormikInput label="Cargo" name="position" formikProps={props} type="select" disabled={!canAdmin}>
                    <option value="" disabled>Seleccione</option>
                    {positions.map(it => <option key={it._id} value={it._id}>{it.name}</option>)}
                  </FormikInput>

                  {
                    canAdmin ?
                      <div className="text-center mt-5">
                        <Button type="submit" disabled={props.isSubmitting}>Guardar</Button>
                      </div>
                      :
                      null
                  }
                </form>
              }
            </Formik>
          </Col>
        </Row>
      </DashboardLayout>
    )
  }

  renderAdminToggle () {
    const { canAdmin, isKeyUser } = this.state

    if (!canAdmin) {
      return null
    }

    if (isKeyUser) {
      return <Button color="warning" onClick={this.toggleIsAdmin} size="sm" block className="mt-4 mb-4">Revocar Key
        User</Button>
    } else {
      return <Button color="primary" onClick={this.toggleIsAdmin} size="sm" block className="mt-4 mb-4">Asignar Key
        User</Button>
    }
  }

  renderChangePassword () {
    const { userId } = this.props.match.params
    const { bundle } = this.context
    const { canAdmin } = this.state

    const isOwnUser = userId === bundle._id

    if (canAdmin || isOwnUser) {
      return <Link color="secondary" className="mt-4 mb-4" to={`/users/${userId}/password`}>Cambiar contraseña</Link>
    }

    return null
  }
}

export default class Wrapper extends React.Component {
  render () {
    const { userId } = this.props.match.params
    const { companyId } = queryString.parse(this.props.location.search)

    return (
      <UserForm {...this.props} userId={userId} companyId={companyId}/>
    )
  }
}
