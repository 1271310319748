import { ENDPOINT_COMPANIES, ENDPOINT_USERS } from '../config'

const TRANSLATE_GENDERS = {
  Male: 'Masculino',
  Female: 'Femenino',
  Other: 'Otro',
}

export const fetchCompanies = (token) => {
  return fetch(`${ENDPOINT_COMPANIES}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  }).then(res => res.json())
}

export const createCompany = (token, values) => {
  return fetch(`${ENDPOINT_COMPANIES}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(values)
  }).then(res => res.json())
}

export const updateCompany = (token, companyId, values) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(values)
  }).then(res => res.json())
}

export const fetchCompany = (token, companyId) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  }).then(res => res.json())
}

export const fetchCompanyByRut = (token, companyRut) => {
  return fetch(`${ENDPOINT_COMPANIES}/byRut?rut=${companyRut}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  }).then(res => res.json())
}

export const addCompanyVendor = (token, companyId, vendorId) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/vendors`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify({ vendorId })
  }).then(res => res.json())
}

export const fetchCompanyVendors = (token, companyId) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/vendors`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  }).then(res => res.json())
}


export const fetchCompanyUsers = async (token, companyId) => {
  let users = await fetch(`${ENDPOINT_COMPANIES}/${companyId}/users`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    }
  })
  users = await users.json()
  const result = []

  for (let user of users) {
    const fullUser = await fetchCompanyUser(token, companyId, user._id)
    fullUser.gender = TRANSLATE_GENDERS[fullUser.gender]
    result.push(fullUser)
  }

  return result
}

export const fetchCompanyUser = async (token, companyId, userId) => {
  return fetch(`${ENDPOINT_USERS}/${userId}?companyId=${companyId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    }
  }).then(res => res.json())
}

export const addCompanyUser = (token, companyId, rut, values) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/users`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({ ...values, rut })
  }).then(res => res.json())
}

export const fetchIsAdmin = async (token, companyId, userId) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/admins/isAdmin?userId=${userId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    }
  }).then(res => res.json())
}

export const addCompanyAdmin = async (token, companyId, userId) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/admins`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({ userId })
  }).then(res => res.json())
}


export const deleteCompanyAdmin = async (token, companyId, userId) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/admins/${userId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  }).then(res => res.json())
}

export const updateCompanyPhoto = (token, companyId, file) => {
  let formData = new FormData()
  formData.append('file', file)

  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/photo`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    },
    body: formData
  }).then(res => res.json())
}

export const companyMassiveImport = (token, companyId, file) => {
  let formData = new FormData()
  formData.append('file', file)

  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/users/import`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    },
    body: formData
  }).then(res => res.json())
}
