import { ENDPOINT_USERS } from '../config'

export const fetchUsers = (token) => {
  return fetch(ENDPOINT_USERS, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    }
  }).then(res => res.json())
}

export const searchUsers = (token, query, companyId) => {
  const endpoint = companyId ?
    `${ENDPOINT_USERS}/search?q=${query}&companyId=${companyId}` :
    `${ENDPOINT_USERS}/search?q=${query}`

  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    }
  }).then(res => res.json())
}

export const fetchUserByRut = (token, userRut) => {
  return fetch(`${ENDPOINT_USERS}/byRut?rut=${userRut}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
  }).then(res => res.json())
}

export const createUser = (token, values) => {
  return fetch(`${ENDPOINT_USERS}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(values)
  }).then(res => res.json())
}

export const updateUser = (token, userId, values) => {
  return fetch(`${ENDPOINT_USERS}/${userId}`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(values)
  }).then(res => res.json())
}

export const updateCompanyUser = (token, companyId, userId, values) => {
  return fetch(`${ENDPOINT_USERS}/${userId}?companyId=${companyId}`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(values)
  }).then(res => res.json())
}

export const fetchUser = (token, userId) => {
  return fetch(`${ENDPOINT_USERS}/${userId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    }
  }).then(res => res.json())
}

export const fetchUserCompanies = (token, userId) => {
  return fetch(`${ENDPOINT_USERS}/${userId}/companies`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    }
  }).then(res => res.json())
}

export const updateUserPassword = (token, userId, values) => {
  return fetch(`${ENDPOINT_USERS}/${userId}/password`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify(values)
  }).then(res => res.json())
}

export const updateUserPhoto = (token, userId, file) => {
  let formData = new FormData()
  formData.append('file', file)

  return fetch(`${ENDPOINT_USERS}/${userId}/photo`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json'
    },
    body: formData
  }).then(res => res.json())
}
