import React from 'react'
import { Redirect, Route, Switch } from 'react-router'

import Login from '../containers/Login'
import CompanyCreate from '../containers/CompanyCreate'
import CompanyDetail from '../containers/CompanyDetail'
import CompanyChart from '../containers/CompanyChart'
import CompanyUsers from '../containers/CompanyUsers'
import KeyUserCreate from '../containers/KeyUserCreate'
import UserForm from '../containers/UserForm'
import DashboardLayout from '../components/Layout/DashboardLayout'
import UserPassword from '../containers/UserPassword'
import Users from '../containers/Users'
import CompanyDocumentDefinitions from '../containers/CompanyDocumentDefinitions'
import UserPermissions from '../containers/UserPermissions'
import CompanyPositions from '../containers/CompanyPositions'
import UserDocuments from "../containers/UserDocuments";
import UserCompanies from '../containers/UserCompanies'
import CompanyVendors from '../containers/CompanyVendors'
import Companies from '../containers/Companies'

export default class RootRouter extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/admin/companies" exact component={Companies}/>

        <Route path="/companies/create" exact component={CompanyCreate}/>
        <Route path="/companies/:companyId/chart" exact component={CompanyChart}/>
        <Route path="/companies/:companyId/detail" exact component={CompanyDetail}/>
        <Route path="/companies/:companyId/users" exact component={CompanyUsers}/>
        <Route path="/companies/:companyId/positions" exact component={CompanyPositions}/>
        <Route path="/companies/:companyId/documentDefinitions" exact component={CompanyDocumentDefinitions}/>
        <Route path="/companies/:companyId/vendors" exact component={CompanyVendors}/>

        <Route path="/admin/users" exact component={Users}/>
        <Route path="/users/:userId/detail" exact component={UserForm}/>
        <Route path="/users/:userId/permissions" exact component={UserPermissions}/>
        <Route path="/users/:userId/documents" exact component={UserDocuments}/>
        <Route path="/users/:userId/password" exact component={UserPassword}/>
        <Route path="/users/companies" exact component={UserCompanies}/>

        <Route path="/:companyId/keyUser/create" exact component={KeyUserCreate}/>

        <Route path="/login" exact component={Login}/>
        <Route path="/" exact render={() => (<Redirect to="/login"/>)}/>
        <Route path="/dashboard" exact component={DashboardLayout}/>
      </Switch>
    )
  }
}
