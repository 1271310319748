import React from 'react'
import DashboardLayout from '../components/Layout/DashboardLayout'
import ListTable from '../components/ListTable/ListTable'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormikInput from '../components/Form/FormikInput'
import MultiInput from '../components/Form/MultiInput'
import { createPosition, fetchPositions, updatePosition } from '../requests/positions'
import { UserContext } from '../contexts'
import { toast } from 'react-toastify'

export default class CompanyDetail extends React.Component {
  static contextType = UserContext

  state = {
    formModalOpen: false,
    inputActivities: [],
    positions: [],
    position: {},
  }

  componentDidMount () {
    this.refreshPositions()
  }

  refreshPositions () {
    const { token } = this.context
    const { companyId } = this.props.match.params

    return fetchPositions(token, companyId).then(res => {
      this.setState({ positions: res })
    })
  }

  handleSubmit = (form, { setSubmitting }) => {
    const { companyId } = this.props.match.params
    const { token } = this.context
    const { position } = this.state

    form = { ...form, activities: this.state.inputActivities }

    if (position._id) {
      updatePosition(token, companyId, position._id, form)
        .then(this.handleResult('Se ha creado el cargo', setSubmitting))
    } else {
      createPosition(token, companyId, form)
        .then(this.handleResult('Se ha actualizado el cargo', setSubmitting))
    }
  }

  handleResult = (message, setSubmitting) => (res) => {
    setSubmitting(false)
    this.toggleFormModal()

    if (res.errors) {
      toast.error(res.message)
    } else {
      this.refreshPositions()
      toast.success(message)
    }
  }

  handlePositionEdit = (position) => {
    this.setState({ position, inputActivities: position.activities })
    this.toggleFormModal()
  }

  handleActivitiesChange = (newActivities) => {
    this.setState({ inputActivities: newActivities })
  }

  handleAddButtonClick = () => {
    this.setState({ position: {}, inputActivities: [] })
    this.toggleFormModal()
  }

  toggleFormModal = () => {
    this.setState({ formModalOpen: !this.state.formModalOpen })
  }

  render () {
    const { positions } = this.state
    const { token } = this.context

    return (
      <DashboardLayout companyId={this.props.match.params.companyId} heading="Administración del Cargo" token={token}>
        <Button onClick={this.handleAddButtonClick} size="sm">+ Agregar Cargo</Button>
        <p/>

        <ListTable onEdit={this.handlePositionEdit} data={positions} columns={[
          { key: 'name', display: 'Nombre' },
          { key: 'description', display: 'Descripción', size: '35%' },
          { key: 'activities', display: 'Actividades' },
        ]}/>

        {this.renderFormModal()}
      </DashboardLayout>
    )
  }

  renderFormModal () {
    return (
      <Modal isOpen={this.state.formModalOpen} toggle={this.toggleFormModal}>
        <ModalHeader toggle={this.toggleFormModal}>Agregar trabajador</ModalHeader>

        <Formik
          onSubmit={this.handleSubmit}
          initialValues={this.state.position}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required')
          })}
        >
          {props =>
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <FormikInput formikProps={props} label="Nombre del cargo" name="name"/>
                <FormikInput formikProps={props} label="Descripción" name="description"/>
                {this.renderMultiInput()}
              </ModalBody>

              <ModalFooter>
                <Button color="danger" onClick={this.toggleFormModal}>Cerrar</Button>
                {' '}
                <Button color="success" type="submit" disabled={props.isSubmitting}>Guardar</Button>
              </ModalFooter>
            </form>
          }
        </Formik>
      </Modal>
    )
  }

  renderMultiInput = () => {
    return (
      <MultiInput values={this.state.inputActivities} onChange={this.handleActivitiesChange} label="Actividades"/>
    )
  }
}
