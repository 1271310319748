import React from 'react'
import PropTypes from 'prop-types'
import { Button, FormGroup, Input, Label, ListGroup, ListGroupItem } from 'reactstrap'
import { UserContext } from '../contexts'
import { fetchUserPermissions, updateUserPermissions } from '../requests/permissions'

export default class Investigaciones extends React.Component {
  static contextType = UserContext

  static propTypes = {
    userId: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
  }

  state = {
    canAdmin: false,
    canAccess: false,
    canCreate: false,
    isLoading: true,
  }

  componentDidMount () {
    const { userId, companyId } = this.props
    const { token } = this.context

    fetchUserPermissions(token, userId, companyId, 'investigaciones')
      .then(permissions => {
        if (!permissions.error) {
          this.setState({
            ...permissions,
            isLoading: false,
          })
        } else if (permissions.error.message.startsWith('NotFoundError')) {
          this.setState({
            isLoading: false,
          })
        }
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { userId, companyId } = this.props
    const { canAccess, canCreate, canAdmin } = this.state
    const { token } = this.context

    this.setState({
      isLoading: true,
    })

    updateUserPermissions(token, userId, companyId, 'investigaciones', {
      canAccess, canCreate, canAdmin
    }).then(permissions => {
      this.setState({
        ...permissions,
        isLoading: false,
      })
    })
  }

  render () {
    const { canAccess, canCreate, canAdmin, isLoading } = this.state

    if (isLoading) {
      return 'Cargando...'
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <ListGroup>
          <ListGroupItem>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={canAdmin}
                       onChange={() => this.setState({ canAdmin: !canAdmin })}/> Puede administrar
              </Label>
            </FormGroup>
          </ListGroupItem>

          <ListGroupItem>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={canAccess}
                       onChange={() => this.setState({ canAccess: !canAccess })}/> Puede
                acceder
              </Label>
            </FormGroup>
          </ListGroupItem>

          <ListGroupItem>
            <FormGroup check>
              <Label check>
                <Input type="checkbox" checked={canCreate}
                       onChange={() => this.setState({ canCreate: !canCreate })}/> Puede crear
              </Label>
            </FormGroup>
          </ListGroupItem>
        </ListGroup>

        <div className="mt-4">
          <Button type="submit">Guardar cambios</Button>
        </div>
      </form>
    )
  }
}
