import React from 'react'
import Chart from '../components/Chart/Chart'
import UserAutocompleteInput from '../components/Form/UserAutocompleteInput'
import UserAutocompleteMultiInput from '../components/Form/UserAutocompleteMultiInput'
import DashboardLayout from '../components/Layout/DashboardLayout'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormikInput from '../components/Form/FormikInput'
import { Modal, ModalHeader, ModalFooter, ModalBody, Button } from 'reactstrap'
import { UserContext } from '../contexts'
import { toast } from 'react-toastify'
import { createArea, deleteArea, fetchAreas, updateArea } from '../requests/areas'
import { fetchUserByRut } from '../requests/users'

const rootNode = {
  name: 'Empresa',
}

export default class CompanyChart extends React.Component {
  static contextType = UserContext

  state = {
    formModalOpen: false,
    deleteModalOpen: false,
    node: {},
    ownerRut: '',
    areaUsers: [],
    initialValues: {},
    root: rootNode,
    areas: []
  }

  componentDidMount () {
    this.reloadAreas()
  }

  reloadAreas = () => {
    const { companyId } = this.props.match.params

    fetchAreas(this.context.token, companyId)
      .then(res => {
        res = res.filter(it => it.enabled)

        this.setState({
          root: processChart(res),
          areas: res
        })
      })
  }

  onNodeClick = (node) => {
    if (node.collapse === undefined) {
      node.collapse = true
    } else {
      node.collapse = !node.collapse
    }

    this.setState({})
  }

  toggleFormModal = () => {
    this.setState({
      formModalOpen: !this.state.formModalOpen,
      ownerRut: '',
      initialValues: {},
      areaUsers: [],
    })
  }

  toggleDeleteModal = () => {
    this.setState({ deleteModalOpen: !this.state.deleteModalOpen })
  }

  handleOwnerChange = (ownerRut) => {
    this.setState({ ownerRut })
  }

  handleNodeEditClick = (node) => {
    this.setState({
      node,
      formModalOpen: true,
      areaUsers: node.users,
      initialValues: {
        name: node.name,
        ownerRut: node.owner.rut,
        parent: node.parent
      }
    })
  }

  handleNodeAddClick = (node) => {
    this.setState({
      formModalOpen: true,
      node: {},
      initialValues: {
        parent: node._id
      }
    })
  }

  handleNodeDeleteClick = (node) => {
    this.setState({ node })
    this.toggleDeleteModal()
  }

  handleAreaUsersChange = (areaUsers) => {
    this.setState({
      areaUsers
    })
  }

  handleSubmit = (form, { setSubmitting }) => {
    const { companyId } = this.props.match.params
    const { token } = this.context
    const { areaUsers, ownerRut } = this.state

    if (form.hasOwnProperty('parent') && !form.parent) {
      delete form.parent
    }

    // get the user id by rut.
    fetchUserByRut(token, ownerRut)
      .then(user => {
        if (this.handleIfError(user)) {
          form.owner = user._id
          form.users = areaUsers.map(user => user._id)

          if (this.state.node._id) {
            this._performUpdate(companyId, form, setSubmitting)
          } else {
            this._performCreate(companyId, form, setSubmitting)
          }
        } else {
          setSubmitting(false)
        }
      })
  }

  handleDeleteSubmit = () => {
    const { companyId } = this.props.match.params
    this._performDelete(companyId, this.state.node._id)
  }

  handleAddAreaButtonClick = () => {
    this.resetForm()
    this.toggleFormModal()
  }

  resetForm = () => {
    this.setState({
      node: {},
      initialValues: {},
    })
  }

  _performCreate = (companyId, form, setSubmitting) => {
    createArea(this.context.token, companyId, form)
      .then(res => {
        setSubmitting(false)
        this.toggleFormModal()

        if (this.handleIfError(res)) {
          this.reloadAreas()
          toast.success('Se ha creado una nueva área')
        }
      })
  }

  _performUpdate = (companyId, form, setSubmitting) => {
    updateArea(this.context.token, companyId, this.state.node._id, form)
      .then(res => {
        setSubmitting(false)
        this.toggleFormModal()

        if (this.handleIfError(res)) {
          this.reloadAreas()
          toast.success('Se ha actualizado el área')
        }
      })
  }

  _performDelete = (companyId, areaId) => {
    deleteArea(this.context.token, companyId, areaId)
      .then(res => {
        if (this.handleIfError(res)) {
          this.reloadAreas()
          this.toggleDeleteModal()
          toast.success('Área eliminada')
        }
      })
  }

  /**
   * Checks if the response was successful.
   * @returns {boolean} true if the response was successful, false if not.
   */
  handleIfError = (res) => {
    if (res.errors) {
      toast.error(res.message)
      return false
    }

    return true
  }

  render () {
    const { companyId } = this.props.match.params
    const { token } = this.context

    return (
      <DashboardLayout heading="Organigrama" companyId={companyId} token={token}>
        <Button onClick={this.handleAddAreaButtonClick} size="sm">+ Agregar Área</Button>
        <p/>

        <Chart
          root={this.state.root}
          onNodeClick={this.onNodeClick}
          onEditClick={this.handleNodeEditClick}
          onAddClick={this.handleNodeAddClick}
          onDeleteClick={this.handleNodeDeleteClick}
        />
        {this.renderFormModal()}
        {this.renderDeleteModal()}
      </DashboardLayout>
    )
  }

  renderFormModal () {
    return (
      <Modal isOpen={this.state.formModalOpen} toggle={this.toggleFormModal}>
        <ModalHeader toggle={this.toggleFormModal}>Área</ModalHeader>

        <Formik
          onSubmit={this.handleSubmit}
          initialValues={this.state.initialValues}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Required'),
            parent: Yup.string(),
          })}
        >
          {props =>
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <FormikInput formikProps={props} label="Nombre" name="name"/>

                <UserAutocompleteInput
                  token={this.context.token}
                  value={this.state.ownerRut}
                  label="Jefe de area"
                  onChange={this.handleOwnerChange}/>

                <FormikInput formikProps={props} label="Área padre" name="parent" type="select">
                  <option>Ninguna</option>
                  {this.state.areas.map(area =>
                    <option key={area._id} value={area._id}>{area.name}</option>
                  )}
                </FormikInput>

                <hr/>

                <UserAutocompleteMultiInput
                  token={this.context.token}
                  values={this.state.areaUsers}
                  label="Trabajadores"
                  onChange={this.handleAreaUsersChange}/>
              </ModalBody>

              <ModalFooter>
                <Button color="danger" onClick={this.toggleFormModal}>Cerrar</Button>
                {' '}
                <Button color="success" type="submit" disabled={props.isSubmitting}>Guardar</Button>
              </ModalFooter>
            </form>
          }
        </Formik>
      </Modal>
    )
  }

  renderDeleteModal () {
    return (
      <Modal isOpen={this.state.deleteModalOpen} toggle={this.toggleDeleteModal}>
        <ModalHeader toggle={this.toggleDeleteModal}>Área</ModalHeader>

        <ModalBody>
          ¿Está seguro que desea eliminar el área <strong>{this.state.node.name}</strong>?
        </ModalBody>

        <ModalFooter>
          <Button color="danger" onClick={this.toggleDeleteModal}>Cerrar</Button>
          {' '}
          <Button color="success" onClick={this.handleDeleteSubmit}>Eliminar</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

const processChart = (areas) => {
  const byId = {}

  const root = {
    ...rootNode,
    children: []
  }

  areas.forEach(area => {
    area.children = []
    area.owner.fullName = `${area.owner.firstNames} ${area.owner.lastNames}`
    byId[area._id] = area
  })

  for (let area of areas) {
    if (area.parent) {
      byId[area.parent].children.push(area)
    } else {
      root.children.push(area)
    }
  }

  return root
}
