import React from 'react'
import { FormFeedback, FormGroup, Label, Input } from 'reactstrap'
import PropTypes from 'prop-types'
import styles from './FormikInput.module.scss'

export default class FormikInput extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    formikProps: PropTypes.object.isRequired,
    key: PropTypes.string,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    type: 'text',
    placeholder: '',
    disabled: false,
  }

  render() {
    const key = this.props.key || this.props.name

    return (
      <FormGroup>
        <Label className="d-block">
          <div className={styles.label}>{this.props.label}</div>

          <Input
            disabled={this.props.disabled}
            type={this.props.type}
            name={this.props.name}
            value={this.props.formikProps.values[key] || ''}
            onChange={this.props.formikProps.handleChange}
            onBlur={this.props.formikProps.handleBlur}
            invalid={!!this.props.formikProps.errors[key]}
            placeholder={this.props.placeholder}
          >
            {this.props.children}
          </Input>

          {
            this.props.formikProps.errors[ key ] && this.props.formikProps.touched[ key ] &&
            <FormFeedback>{this.props.formikProps.errors[ key ]}</FormFeedback>
          }
        </Label>
      </FormGroup>
    )
  }
}
