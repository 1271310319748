import React from 'react'
import styles from './MinimalLayout.module.scss'

export default class MinimalLayout extends React.PureComponent {
  render() {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.safeMargin}>
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}