import React from 'react'
import styles from './Node.module.scss'
import pencilIcon from '../../assets/pencil.svg'
import plusIcon from '../../assets/plus.svg'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

export default class Node extends React.Component {
  static propTypes = {
    node: PropTypes.object.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onAddClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
  }

  static width = 200
  static height = 80
  static padding = 5
  static fontSize = 10

  handleDeleteClick = () => {
    if (this.props.node.children && this.props.node.children.length > 0) {
      toast.warn('Para eliminar esta area debe eliminar todos las areas dependientes.', {
        autoClose: 5000,
      })
    } else {
      this.props.onDeleteClick(this.props.node)
    }
  }

  render() {
    const { x, y } = this.props

    return (
      <g
        transform={`translate(${x - Node.width / 2 + Node.padding}, ${y + Node.padding})`}
        className={styles.container}
      >
        <rect
          width={Node.width - Node.padding * 2}
          height={Node.height - Node.padding * 2}
          className={styles.rect}
          stroke="#666666"
          rx={5}
          ry={5}
          strokeWidth={1}
          fill="#ffffff"
          onClick={() => this.props.onNodeClick(this.props.node)}
        />

        {this.renderName()}
        {this.props.node.owner && this.renderOwner()}
        {this.renderButtons()}
      </g>
    )
  }

  renderButtons() {
    return (
      <g transform={`translate(0, ${Node.height - 20})`}>
        {this.props.node.owner && this.renderEditButton()}
        {this.renderAddButton()}
        {this.props.node.owner && this.renderDeleteButton()}
      </g>
    )
  }

  renderAddButton() {
    return (
      <image
        href={plusIcon}
        height="12"
        width="12"
        y={-8}
        x={Node.width / 2 - 11}
        onClick={() => this.props.onAddClick(this.props.node)}
      />
    )
  }

  renderEditButton() {
    return (
      <image
        href={pencilIcon}
        height="12"
        width="12"
        x={8}
        y={-8}
        onClick={() => this.props.onEditClick(this.props.node)}
      />
    )
  }

  renderDeleteButton() {
    const isDeleteDisabled = this.props.node.children && this.props.node.children.length > 0

    return (
      <g transform={`translate(${Node.width - 28}, ${-8}) scale(0.5, 0.5)`}
         className={classNames(isDeleteDisabled && styles.disabled)}>
        <path
          d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/>
        <rect fill="transparent" width={24} height={24} onClick={this.handleDeleteClick}/>
      </g>
    )
  }

  renderName() {
    return (
      <text
        x={Node.width / 2 - Node.padding} y={12}
        textAnchor="middle"
        alignmentBaseline="central"
        fontSize={Node.fontSize}
        fill="#62ACEC"
        fontWeight="bold"
        onClick={() => this.props.onNodeClick(this.props.node)}
      >
        {this.props.node.name}
      </text>
    )
  }

  renderOwner() {
    if (!this.props.node.owner) {
      return null
    }

    return (
      <text
        x={Node.width / 2 - Node.padding} y={Node.height / 2 - Node.padding}
        textAnchor="middle"
        alignmentBaseline="central"
        fontSize={Node.fontSize}
        onClick={() => this.props.onNodeClick(this.props.node)}
      >
        {this.props.node.owner.fullName}
      </text>
    )
  }
}
