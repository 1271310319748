import React from 'react'
import { Button } from 'reactstrap'
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormikInput from '../components/Form/FormikInput'

import DashboardLayout from '../components/Layout/DashboardLayout'
import ListTable from '../components/ListTable/ListTable'
import { UserContext } from '../contexts'
import { toast } from 'react-toastify'
import { createCompany, fetchCompanies, updateCompany } from '../requests/companies'
import FormikInputGenerator from '../components/Form/FormikInputGenerator'
import * as companyForm from '../forms/companyForm'
import { fetchSiiRecord } from '../requests/sii'

export default class Companies extends React.Component {
  static contextType = UserContext

  static propTypes = {}

  state = {
    createMode: 'rut',
    formModalOpen: false,
    company: {},
    companies: []
  }

  componentDidMount () {
    this.refreshCompanies()
  }

  refreshCompanies = () => {
    const { token } = this.context

    fetchCompanies(token)
      .then(companies => this.setState({ companies }))
  }

  handleEdit = (company) => {
    this.setState({
      company,
      createMode: 'create',
      formModalOpen: true
    })
  }

  handleSubmit = (form, { setSubmitting }) => {
    const { token } = this.context
    const { company, createMode } = this.state

    const { rut } = form

    if (createMode === 'rut') {
      fetchSiiRecord(rut)
        .then(this.handleSiiResult)
    } else {
      if (company._id) {
        updateCompany(token, company._id, form)
          .then(this.handleResult('Se ha actualizado la empresa', setSubmitting))
      } else {
        createCompany(token, form)
          .then(this.handleResult('Se ha creado la empresa', setSubmitting))
      }
    }
  }

  handleSiiResult = (result) => {
    if (result.error) {
      toast.error('No encontramos la empresa en el Servicio de Impuestos Internos.')
    } else {
      const form = { businessName: result.business_name, rut: result.rut }

      this.setState({
        createMode: 'form',
        company: form
      })
    }
  }

  handleResult = (message, setSubmitting) => (res) => {
    setSubmitting(false)
    this.toggleFormModal()

    if (res.errors) {
      toast.error(res.message)
    } else {
      this.refreshCompanies()
      toast.success(message)
    }
  }

  toggleFormModal = () => {
    this.setState({
      createMode: 'rut',
      company: {},
      formModalOpen: !this.state.formModalOpen
    })
  }

  render () {
    const { companies, createMode } = this.state

    return (
      <DashboardLayout heading="Empresas">
        <Button onClick={this.toggleFormModal} size="sm">+ Agregar empresa</Button>
        <p/>

        <ListTable data={companies} onEdit={this.handleEdit} columns={[
          { key: 'name', display: 'Empresa', linkTo: row => `/companies/${row._id}/detail` },
          { key: 'rut', display: 'RUT' },
          { key: 'category', display: 'Giro' },
        ]}/>

        {createMode === 'rut' ? this.renderRutFormModal() : this.renderFormModal()}
      </DashboardLayout>
    )
  }

  renderRutFormModal () {
    const { formModalOpen, company } = this.state

    return (
      <Modal isOpen={formModalOpen} toggle={this.toggleFormModal}>
        <ModalHeader toggle={this.toggleFormModal}>Empresa</ModalHeader>

        <Formik
          onSubmit={this.handleSubmit}
          initialValues={company}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            rut: Yup.string().required('Requerido'),
          })}
        >
          {props =>
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <p><strong>Ingrese el RUT de la empresa</strong>. Usaremos esta información para buscar los datos
                  legales asociados a ella.</p>

                <FormikInput formikProps={props} label="RUT" name="rut"/>
              </ModalBody>

              <ModalFooter>
                <Button color="danger" onClick={this.toggleFormModal}>Cerrar</Button>
                {' '}
                <Button color="success" type="submit" disabled={props.isSubmitting}>Guardar</Button>
              </ModalFooter>
            </form>
          }
        </Formik>
      </Modal>
    )
  }

  renderFormModal () {
    const { formModalOpen, company } = this.state

    return (
      <Modal isOpen={formModalOpen} toggle={this.toggleFormModal}>
        <ModalHeader toggle={this.toggleFormModal}>Empresa</ModalHeader>

        <Formik
          onSubmit={this.handleSubmit}
          initialValues={company}
          enableReinitialize={true}
          validationSchema={companyForm.validation}
        >
          {props =>
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <FormikInputGenerator fields={companyForm.fields} formikProps={props}/>
              </ModalBody>

              <ModalFooter>
                <Button color="danger" onClick={this.toggleFormModal}>Cerrar</Button>
                {' '}
                <Button color="success" type="submit" disabled={props.isSubmitting}>Guardar</Button>
              </ModalFooter>
            </form>
          }
        </Formik>
      </Modal>
    )
  }
}
