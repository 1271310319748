import React from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import { UserContext } from '../../contexts'
import PropTypes from 'prop-types'

export default class UserNavigation extends React.PureComponent {
  static contextType = UserContext

  static propTypes = {
    location: PropTypes.any,
    companyId: PropTypes.string,
    userId: PropTypes.string,
    canAdmin: PropTypes.bool
  }

  render () {
    const { canAdmin, userId, companyId } = this.props

    const href = this.props.location.pathname

    return (
      <div>
        <Link to={`/users/${userId}/detail?companyId=${companyId}`} href={href}>Datos Personales</Link>
        {canAdmin && <Link to={`/users/${userId}/permissions?companyId=${companyId}`} href={href}>Permisos</Link>}
        <Link to={`/users/${userId}/documents?companyId=${companyId}`} href={href}>Documentos</Link>
      </div>
    )
  }
}

const Link = ({ to, href, children }) => {
  return (
    <NavLink
      to={to}
      className={classNames('btn', 'btn-sm', 'mr-2', to.startsWith(href) ? 'btn-primary' : 'btn-secondary')}
    >
      {children}
    </NavLink>
  )
}
