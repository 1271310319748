import React from 'react'
import styles from './ListTable.module.scss'
import { Table, Button } from 'reactstrap'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export default class ListTable extends React.Component {
  static propTypes = {
    data: PropTypes.any.isRequired,
    columns: PropTypes.any.isRequired,
    onEdit: PropTypes.func,
  }

  render () {
    return (
      <Table striped={true}>
        <thead className={styles.heading}>
        <tr>
          {this.props.columns.map((column, i) => <th key={i} width={column.size}>{column.display}</th>)}
          {this.props.onEdit && <th/>}
        </tr>
        </thead>

        <tbody>
        {this.props.data.map(this.renderRow)}
        </tbody>
      </Table>
    )
  }

  renderRow = (row, i) => {
    return (
      <tr key={i}>
        {this.props.columns.map((column, i) => this.renderCell(row, column, i))}

        {
          this.props.onEdit &&
          <td><Button color="primary" onClick={() => this.props.onEdit(row)} size="sm">Editar</Button></td>
        }
      </tr>
    )
  }

  renderCell = (row, column, i) => {
    let toRender = ''

    if (column.render) {
      toRender = column.render(row)
    } else if (column.linkTo) {
      const link = column.linkTo(row)

      if (link) {
        if (column.asHref) {
          toRender = <a href={column.linkTo(row)} target="_blank" rel="noopener noreferrer">{this.renderText(row[column.key], i)}</a>
        } else {
          toRender = <Link to={column.linkTo(row)}>{this.renderText(row[column.key], i)}</Link>
        }

      } else {
        toRender = this.renderText(row[column.key], i)
      }
    } else {
      toRender = this.renderText(row[column.key], i)
    }

    return <td key={i}>{toRender}</td>
  }

  renderText = (text, i) => {
    if (Array.isArray(text)) {
      return text.map((line, k) => <div key={i * k}>{k + 1}) {line}</div>)
    } else {
      return text
    }
  }
}
