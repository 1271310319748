import { ENDPOINT_COMPANIES } from '../config'

export const createArea = (token, companyId, values) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/areas`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': `application/json`,
    },
    body: JSON.stringify(values)
  }).then(res => res.json())
}

export const updateArea = (token, companyId, areaId, values) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/areas/${areaId}`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': `application/json`,
    },
    body: JSON.stringify(values)
  }).then(res => res.json())
}

export const deleteArea = (token, companyId, areaId) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/areas/${areaId}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': `application/json`,
    }
  }).then(res => res.json())
}

export const fetchAreas = (token, companyId) => {
  return fetch(`${ENDPOINT_COMPANIES}/${companyId}/areas`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  }).then(res => res.json())
}
