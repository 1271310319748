import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Button } from 'reactstrap'
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap'
import { Formik } from 'formik'
import FormikInputGenerator from '../components/Form/FormikInputGenerator'
import DashboardLayout from '../components/Layout/DashboardLayout'
import * as userForm from '../forms/userForm'
import { addCompanyUser, fetchCompanyUsers } from '../requests/companies'
import ListTable from '../components/ListTable/ListTable'
import FormikInput from '../components/Form/FormikInput'
import { UserContext } from '../contexts'
import { toast } from 'react-toastify'
import { fetchPositions } from '../requests/positions'
import { createUser, fetchUserByRut, updateUser } from '../requests/users'

class CompanyVendors extends React.Component {
  static contextType = UserContext

  static propTypes = {
    companyId: PropTypes.string.isRequired,
  }

  state = {
    formModalOpen: false,
    createMode: 'search',
    user: {},
    users: [],
    positions: []
  }

  componentDidMount () {
    this.refreshUsers()
    this.refreshPositions()
  }

  refreshUsers = () => {
    const { companyId } = this.props
    const { token } = this.context

    fetchCompanyUsers(token, companyId)
      .then(users => this.setState({ users }))
  }

  refreshPositions = () => {
    const { companyId } = this.props
    const { token } = this.context

    fetchPositions(token, companyId)
      .then(positions => this.setState({ positions }))
  }

  handleSearchSubmit = (form, { setSubmitting }) => {
    const { token } = this.context
    const { rut } = form

    fetchUserByRut(token, rut)
      .then(user => {
        if (user.errors) {
          this.setState({
            createMode: 'create',
          })

          toast.warn('Usuario no encontrado, rellene el formulario para ingresarlo.')
          setSubmitting(false)
        } else {
          this.handleUserFound(form, user, setSubmitting)
        }
      })
  }

  handleUserFound = (form, user, setSubmitting) => {
    const { token } = this.context
    const { companyId } = this.props

    toast.success('Trabajador encontrado, realizaremos el enlace automáticamente...')

    addCompanyUser(token, companyId, user.rut, form)
      .then(this.handleResult('Trabajador enlazado con éxito', setSubmitting))
  }

  handleCreateSubmit = (form, { setSubmitting }) => {
    const { companyId } = this.props
    const { token } = this.context
    const { user } = this.state

    if (user._id) {
      updateUser(token, user._id, form)
        .then(this.handleResult('Trabajador actualizado con éxito.', setSubmitting))
    } else {
      createUser(token, form)
        .then(user => {
          if (user.errors) {
            toast.error(user.message)
          } else {
            toast.success('Trabajador creado con éxito, realizaremos el enlace...')

            addCompanyUser(token, companyId, user.rut, form)
              .then(this.handleResult('Tabajador enlazado con éxito', setSubmitting))
          }
        })
    }
  }

  handleResult = (message, setSubmitting) => (res) => {
    setSubmitting(false)
    this.toggleFormModal()

    if (res.errors) {
      toast.error(res.message)
    } else {
      this.refreshUsers()
      toast.success(message)
    }
  }

  toggleFormModal = () => {
    this.setState({ formModalOpen: !this.state.formModalOpen })
  }

  render () {
    const { companyId } = this.props
    const { users } = this.state
    const { token } = this.context

    return (
      <DashboardLayout companyId={companyId} heading="Trabajadores" token={token}>
        <Button onClick={this.toggleFormModal} size="sm">+ Agregar trabajador</Button>
        <p/>

        <ListTable data={users} columns={[
          { key: 'rut', display: 'RUT' },
          { key: 'firstNames', display: 'Nombres', linkTo: row => `/users/${row._id}/detail?companyId=${companyId}` },
          { key: 'lastNames', display: 'Apellidos', linkTo: row => `/users/${row._id}/detail?companyId=${companyId}` },
          { key: 'email', display: 'Email' },
          { key: 'phone', display: 'Teléfono' },
        ]}/>

        {this.renderFormModal()}
      </DashboardLayout>
    )
  }

  renderFormModal () {
    const { createMode } = this.state

    if (createMode === 'search') {
      return this._renderSearchFormModal()
    } else if (createMode === 'create') {
      return this._renderCreateFormModal()
    }
  }

  _renderSearchFormModal () {
    const { formModalOpen, user, positions } = this.state

    return (
      <Modal isOpen={formModalOpen} toggle={this.toggleFormModal}>
        <ModalHeader toggle={this.toggleFormModal}>Trabajador</ModalHeader>

        <Formik
          onSubmit={this.handleSearchSubmit}
          initialValues={user}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            rut: Yup.string().required('Requerido'),
          })}
        >
          {props =>
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <p>
                  <strong>Primero busque al trabajador por su RUT</strong>. En caso de encontrarlo podremos enlazarlo
                  sin ingresar más datos, de lo contrario deberá registrarlo en el sistema.
                </p>

                <FormikInput formikProps={props} label="RUT" name="rut"/>
                <FormikInput formikProps={props} label="Correo Electrónico" name="email"/>
                <FormikInput label="Cargo" name="position" formikProps={props} type="select">
                  <option value="" disabled>Seleccione</option>
                  {positions.map(it => <option key={it._id} value={it._id}>{it.name}</option>)}
                </FormikInput>
              </ModalBody>

              <ModalFooter>
                <Button color="danger" onClick={this.toggleFormModal}>Cerrar</Button>
                {' '}
                <Button color="success" type="submit" disabled={props.isSubmitting}>Buscar</Button>
              </ModalFooter>
            </form>
          }
        </Formik>
      </Modal>
    )
  }

  _renderCreateFormModal () {
    const { formModalOpen, user, positions } = this.state

    return (
      <Modal isOpen={formModalOpen} toggle={this.toggleFormModal}>
        <ModalHeader toggle={this.toggleFormModal}>Trabajador</ModalHeader>

        <Formik
          onSubmit={this.handleCreateSubmit}
          initialValues={user}
          enableReinitialize={true}
          validationSchema={userForm.validationCompany}
        >
          {props =>
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <FormikInputGenerator fields={userForm.fields} formikProps={props}/>
                <FormikInput label="Correo electrónico" name="email" formikProps={props}/>
                <FormikInput label="Cargo" name="position" formikProps={props} type="select">
                  <option value="" disabled>Seleccione</option>
                  {positions.map(it => <option key={it._id} value={it._id}>{it.name}</option>)}
                </FormikInput>
              </ModalBody>

              <ModalFooter>
                <Button color="danger" onClick={this.toggleFormModal}>Cerrar</Button>
                {' '}
                <Button color="success" type="submit" disabled={props.isSubmitting}>Buscar</Button>
              </ModalFooter>
            </form>
          }
        </Formik>
      </Modal>
    )
  }
}

export default class Wrapper extends React.Component {
  render () {
    const { companyId } = this.props.match.params

    return (
      <CompanyVendors {...this.props} companyId={companyId}/>
    )
  }
}
