import React from 'react'
import DashboardLayout from '../components/Layout/DashboardLayout'
import queryString from 'query-string'
import UserNavigation from '../components/UserNavigation/UserNavigation'
import { Col, ListGroup, ListGroupItem, Row } from 'reactstrap'
import { Link } from 'react-router-dom'
import { UserContext } from '../contexts'
import Investigaciones from '../permissions/Investigaciones'

class UserPermissions extends React.Component {
  static contextType = UserContext

  render () {
    const { userId, companyId, module } = this.props
    const { token } = this.context

    return (
      <DashboardLayout companyId={companyId} heading="Permisos" token={token}>
        <UserNavigation {...this.props} canAdmin={true} companyId={companyId} userId={userId}/>

        <Row className="mt-3">
          <Col sm={3}>
            <ListGroup>
              <ModuleLink module={`investigaciones`} companyId={companyId}
                          activeModule={module}>Investigaciones</ModuleLink>
              <ModuleLink module={`iper`} companyId={companyId} activeModule={module}>IPER</ModuleLink>
              <ModuleLink module={`hiso`} companyId={companyId} activeModule={module}>HISO</ModuleLink>
              <ModuleLink module={`flex`} companyId={companyId} activeModule={module}>FLEX</ModuleLink>
            </ListGroup>
          </Col>

          <Col>
            {this.renderPermissions()}
          </Col>
        </Row>
      </DashboardLayout>
    )
  }

  renderPermissions () {
    const { module } = this.props
    const { userId, companyId } = this.props

    switch (module) {
      case 'investigaciones':
        return <Investigaciones userId={userId} companyId={companyId}/>
      default:
        return 'En construcción.'
    }
  }
}

const ModuleLink = ({ children, module, companyId, activeModule }) =>
  <ListGroupItem tag={Link} to={`?companyId=${companyId}&module=${module}`}
                 active={module === activeModule}>{children}</ListGroupItem>

export default class Wrapper extends React.Component {
  render () {
    const { userId } = this.props.match.params
    let { companyId, module } = queryString.parse(this.props.location.search)

    module = module || 'investigaciones'

    return (
      <UserPermissions {...this.props} userId={userId} companyId={companyId} module={module}/>
    )
  }
}
