import React from 'react'
import Node from './Node'
import PropTypes from 'prop-types'

export default class Chart extends React.Component {
  static propTypes = {
    root: PropTypes.any.isRequired,
    onEditClick: PropTypes.func,
    onAddClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
  }

  update = 0

  render() {
    const { root } = this.props

    const nodes = []
    const joins = []

    calculateWidths(root)
    this.renderNode(root, 0, 1110, 0, nodes, [1110 / 2, 0], joins)
    joins.shift()

    let lines = this.renderLines(joins)

    return (
      <svg width={1110} height={400} key={this.update++}>
        {nodes}
        {lines}
      </svg>
    )
  }

  renderNode(node, x0, x1, y, nodes, parentCenter, joins) {
    const nodePivot = [(x0 + x1) / 2, y]

    const bottomParent = [parentCenter[0], parentCenter[1] + Node.height]
    joins.push([node, bottomParent, nodePivot])

    if (node.children && !node.collapse) {
      let spaceSoFar = 0

      for (let i = 0; i < node.children.length; i++) {
        let child = node.children[i]

        const cx0 = x0 + (x1 - x0) / node._width * spaceSoFar
        const cx1 = x0 + (x1 - x0) / node._width * (spaceSoFar + child._width)

        spaceSoFar += child._width

        this.renderNode(child, cx0, cx1, y + Node.height, nodes, nodePivot, joins)
      }
    }

    nodes.push(
      <Node
        key={node.name}
        node={node}
        x={(x0 + x1) / 2}
        y={y}
        onNodeClick={this.props.onNodeClick}
        onEditClick={this.props.onEditClick}
        onAddClick={this.props.onAddClick}
        onDeleteClick={this.props.onDeleteClick}
      />
    )
  }

  renderLines(joins) {
    const lines = []

    for (let line of joins) {
      const node = line[0]
      const lineStart = line[1]
      const lineEnd = line[2]

      lines.push(
        <Line key={lines.length} x1={lineStart[0]} y1={lineStart[1] - 5} x2={lineStart[0]} y2={lineStart[1]}/>
      )

      lines.push(
        <Line key={lines.length} x1={lineEnd[0]} y1={lineEnd[1]} x2={lineEnd[0]} y2={lineEnd[1] + 5}/>
      )

      lines.push(
        <Line key={lines.length} x1={lineStart[0]} y1={lineStart[1]} x2={lineEnd[0]} y2={lineEnd[1]}/>
      )

      if (node.children && node.children.length > 0) {
        lines.push(
          <Line key={lines.length} x1={lineEnd[0]} y1={lineEnd[1] + Node.height - 5} x2={lineEnd[0]}
                y2={lineEnd[1] + Node.height}/>
        )
      }
    }

    return lines
  }
}

const calculateWidths = (node) => {
  let totalWidth = 0

  if (!node.children || node.children.length === 0 || node.collapse) {
    node._width = 1
    return 1
  }

  for (let child of node.children) {
    totalWidth += calculateWidths(child)
  }

  node._width = totalWidth
  return totalWidth
}

const Line = (props) =>
  <line {...props} stroke="#666666" strokeWidth={1}/>
