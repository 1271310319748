import React from 'react'
import { Label, Input, FormGroup, Button, InputGroup, InputGroupAddon } from 'reactstrap'
import { searchUsers } from '../../requests/users'
import styles from './FormikInput.module.scss'
import inputStyles from './UserAutocompleteInput.module.scss'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as _ from 'lodash'

export default class UserAutocompleteInput extends React.Component {
  static propTypes = {
    token: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  state = {
    results: [],
    currentValue: '',
  }

  refreshAutocompleteResults = _.debounce((query) => {
    const { token } = this.props

    if (query) {
      searchUsers(token, query)
        .then(results => {
          if (!results.message) {
            this.setState({ results })
          }
        })
    }
  }, 500)

  handleAutocompleteUserClick = (user) => {
    this.props.onChange(user.rut)

    this.setState({
      currentValue: '',
      results: [],
    })
  }

  handleChange = (value) => {
    this.setState({
      currentValue: value,
    })

    this.refreshAutocompleteResults(value)
  }

  render () {
    const { results, currentValue } = this.state
    const { value } = this.props

    return (
      <FormGroup>
        <Label className="d-block">
          <div className={styles.label}>{this.props.label}</div>
        </Label>

        <InputGroup className={classNames('mb-2')}>
          <Input
            value={currentValue || value}
            onChange={e => this.handleChange(e.target.value)}
            placeholder="Ingrese nombre o RUT del usuario..."
          />
        </InputGroup>

        <div>
          <div className={inputStyles.ListDrop}>
            {results.map(user =>
              <div
                onClick={() => this.handleAutocompleteUserClick(user)}
                key={user._id}
                className={inputStyles.Result}
              >
                {user.firstNames} {user.lastNames} / <strong>{user.rut}</strong>
              </div>
            )}
          </div>
        </div>
      </FormGroup>
    )
  }
}
