import React from 'react'
import Box from '../components/Box/Box'
import { Formik } from 'formik'
import * as Yup from 'yup'
import BoxBody from '../components/Box/BoxBody'
import { Button } from 'reactstrap'
import FormikInput from '../components/Form/FormikInput'
import { withRouter } from 'react-router'
import MinimalLayout from '../components/Layout/MinimalLayout'
import { ENDPOINT_COMPANIES, ENDPOINT_USERS } from '../config'

class KeyUserCreate extends React.Component {
  handleSubmit = (form) => {
    const { companyId } = this.props.match.params

    console.log('creating user...')

    fetch(`${ENDPOINT_USERS}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(form)
    })
      .then(res => res.json())
      .then(res => {
        console.log('adding user as company admin...')

        fetch(`${ENDPOINT_COMPANIES}/${companyId}/admins`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({ userId: res._id })
        })
          .then(res => res.json())
          .then(res => {

            console.log('logging in user...')

            fetch(`${ENDPOINT_USERS}/login`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
              },
              body: {
                rut: res.rut,
                password: form.password,
              }
            })
              .then(res => res.json())
              .then(res => {

              })
          })
      })
  }

  nextPage = (companyId) => {
    this.props.history.push(`/company/${companyId}/detail`)
  }

  render() {
    return (
      <MinimalLayout>
        <Box
          title="Configuración Key User"
          subtext={
            <div>
              <p>Ahora, debes seguir el proceso de configuración de la cuenta en unos simples pasos.</p>
              <p>Nota: Key User será agregado como jefe de la unidad principal de la empresa</p>
            </div>
          }
        >
          <BoxBody>
            <Formik
              onSubmit={this.handleSubmit}
              validationSchema={Yup.object().shape({
                firstNames: Yup.string().required('Required'),
                lastNames: Yup.string().required('Required'),
                rut: Yup.string().required('Required'),
                phone: Yup.string().required('Required'),
                gender: Yup.string().required('Required'),
                nationality: Yup.string().required('Required'),
              })}
            >
              {props =>
                <form onSubmit={props.handleSubmit}>
                  <FormikInput formikProps={props} label="Nombres" name="firstNames"/>
                  <FormikInput formikProps={props} label="Apellidos" name="lastNames"/>
                  <FormikInput formikProps={props} label="RUT" name="rut"/>
                  <FormikInput formikProps={props} label="Teléfono" name="phone"/>
                  <FormikInput formikProps={props} label="Nacionalidad" name="nationality"/>

                  <FormikInput formikProps={props} label="Genero" name="gender" type="select">
                    <option value="Female">Femenino</option>
                    <option value="Male">Masculino</option>
                    <option value="Other">Otro</option>
                  </FormikInput>

                  <FormikInput formikProps={props} label="Contraseña" name="password" type="password"/>

                  <div className="text-center mt-5">
                    <Button type="submit" disabled={props.isSubmitting}>Finalizar</Button>
                  </div>
                </form>
              }
            </Formik>
          </BoxBody>
        </Box>
      </MinimalLayout>
    )
  }
}

export default withRouter(KeyUserCreate)
