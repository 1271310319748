import React from 'react'
import { UserContext } from '../../contexts'
import { fetchCompany, fetchIsAdmin } from '../../requests/companies'
import styles from './DashboardLayout.module.scss'
import { Col, Container, Row } from 'reactstrap'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import logo from '../../assets/logo2.png'


class DashboardLayout extends React.PureComponent {
  static propTypes = {
    heading: PropTypes.string,
    companyId: PropTypes.string,
    token: PropTypes.string,
    bundle: PropTypes.object,
    logout: PropTypes.func,
  }

  state = {
    company: {},
    canAdmin: false,
  }

  componentDidMount () {
    this.refreshCompany()
    this.refreshCanAdmin()
  }

  refreshCompany = () => {
    const { token, companyId, bundle } = this.props

    if (!companyId) {
      return
    }

    if (!token) {
      throw new Error('missing token in DashboardLayout')
    }

    fetchCompany(token, companyId)
      .then(company => {
        const isKeyUser = !!company.admins.find(it => it === bundle._id)

        this.setState({
          company,
          isKeyUser
        })
      })
  }

  refreshCanAdmin = () => {
    const { companyId, bundle, token } = this.props

    if (bundle.isAdmin) {
      this.setState({ canAdmin: bundle.isAdmin })
    } else {
      fetchIsAdmin(token, companyId, bundle._id)
        .then(canAdmin => this.setState({ canAdmin }))
    }
  }

  render () {
    return (
      <React.Fragment>
        {this.renderTopNavbar()}

        <Container fluid={true}>
          <Row>
            {this.renderSidebar()}

            <Col tag="main" sm={10} role="main" className={classNames('ml-sm-auto', 'pt-3', 'px-4', styles.content)}>
              {this.props.heading && <h1 className={styles.heading}>{this.props.heading}</h1>}

              {this.props.children}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }

  renderSidebar () {
    const { companyId, bundle } = this.props
    const { company } = this.state

    function redirectInvestigations () {
      window.location.href = `https://bhp-investigaciones.dev.lifeon.cl/#token=${localStorage.getItem('token')}`
    }

    function redirectCourses () {
      const host = window.location.host.replace('usuarios', 'cursos')
      const protocol = window.location.protocol
      window.location.href = `${protocol}//${host}/?token=${localStorage.getItem('token')}`
    }

    return (
      <nav className={classNames('col-sm-2', 'bg-light', styles.sidebar)}>
        <div className={styles.sticky}>
          {
            !bundle.isAdmin &&
            <ul className="nav flex-column">
              <li><Link className={classNames(styles.navLink)}
                        to={`/users/${bundle._id}/detail?companyId=${companyId}`}>Mi Perfil</Link></li>
            </ul>
          }

          <h6>{company.name}</h6>
          {companyId && this.renderCompanyLinks()}

          {bundle.isAdmin && this.renderAdminLinks()}

          <h6>Personal</h6>
          <ul className='nav flex-column'>
            <li><Link className={classNames(styles.navLink)} to={`/users/companies`}>Mis Empresas</Link></li>
          </ul>
          <h6>Módulos</h6>
          <ul className='nav flex-column'>
            <li onClick={redirectCourses} className={classNames(styles.cursor)}>Cursos y Capacitaciones</li>
          </ul>
        </div>
      </nav>
    )
  }

  renderCompanyLinks = () => {
    const { companyId } = this.props
    const { canAdmin } = this.state

    return (
      <ul className="nav flex-column">
        <li><Link className={classNames(styles.navLink)} to={`/companies/${companyId}/detail`}>Empresa</Link></li>
        {canAdmin &&
        <li><Link className={classNames(styles.navLink)} to={`/companies/${companyId}/chart`}>Organigrama</Link></li>}
        {canAdmin &&
        <li><Link className={classNames(styles.navLink)} to={`/companies/${companyId}/users`}>Trabajadores</Link>
        </li>}
        {canAdmin && <li><Link className={classNames(styles.navLink)} to={`/companies/${companyId}/vendors`}>Empresas
          contratistas</Link></li>}
        {canAdmin &&
        <li><Link className={classNames(styles.navLink)} to={`/companies/${companyId}/positions`}>Administración del
          Cargo</Link></li>}
        {canAdmin &&
        <li><Link className={classNames(styles.navLink)} to={`/companies/${companyId}/documentDefinitions`}>Documentos
          Requeridos</Link></li>}
      </ul>
    )
  }

  renderTopNavbar () {
    const { company } = this.state
    const { bundle, logout } = this.props

    return (
      <nav className={classNames(styles.navbar, 'navbar', 'navbar-dark', 'sticky-top', 'flex-md-nowrap', 'p-0')}>
        <Link className={classNames(styles.navbarBrand, 'col-sm-3', 'col-md-2', 'mr-0', 'text-center')} style={{
          backgroundColor: '#001d41',
        }} to="/">
          <img src={logo} alt="" height="40px"/>
        </Link>

        <ul className="navbar-nav px-3" style={{ width: '100%' }}>
          <li className="nav-item text-nowrap">
            <div className={styles.right}>
              <div className={styles.message}>
                <div>
                  <strong>Bienvenido</strong>, {' '}

                  {bundle.firstNames} {bundle.lastNames}, {' '}
                  <span className={styles.logoutLink} onClick={logout}>Salir</span>

                  <div className={styles.avatar}>
                    <img src="https://image.flaticon.com/icons/svg/149/149071.svg" alt=""/>
                  </div>

                  <strong>{company.name}</strong>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    )
  }

  renderAdminLinks () {
    return (
      <React.Fragment>
        <h6>Administración</h6>

        <ul className="nav flex-column">
          <li><Link className={classNames(styles.navLink)} to={`/admin/users`}>Usuarios</Link></li>
          <li><Link className={classNames(styles.navLink)} to={`/admin/companies`}>Empresas</Link></li>
        </ul>
      </React.Fragment>
    )
  }
}

export default class Wrapper extends React.Component {
  static contextType = UserContext

  render () {
    const { token, bundle, logout } = this.context

    return (
      <DashboardLayout {...this.props} bundle={bundle} token={token} logout={logout}/>
    )
  }
}
