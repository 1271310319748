import { ENDPOINT_DOCUMENT_DEFINITIONS, ENDPOINT_DOCUMENT_SUBMISSIONS } from '../config'

export const fetchDocumentDefinitions = (token, companyId, type) => {
  const endpoint = type ?
    `${ENDPOINT_DOCUMENT_DEFINITIONS}?companyId=${companyId}&type=${type}` :
    `${ENDPOINT_DOCUMENT_DEFINITIONS}?companyId=${companyId}`

  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  })
    .then(res => res.json())
}

export const createDocumentDefinition = (token, companyId, values) => {
  return fetch(`${ENDPOINT_DOCUMENT_DEFINITIONS}?companyId=${companyId}`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(values)
  })
    .then(res => res.json())
}

export const updateDocumentDefinition = (token, documentId, values) => {
  return fetch(`${ENDPOINT_DOCUMENT_DEFINITIONS}/${documentId}`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(values)
  })
    .then(res => res.json())
}

export const fetchDocumentSubmission = (token, userId, definitionId, companyId) => {
  const url = userId ?
    `${ENDPOINT_DOCUMENT_SUBMISSIONS}?definitionId=${definitionId}&userId=${userId}&companyId=${companyId}` :
    `${ENDPOINT_DOCUMENT_SUBMISSIONS}?definitionId=${definitionId}&companyId=${companyId}`

  return fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  })
    .then(res => res.json())
}

export const createDocumentSubmission = (token, userId, definitionId, file, values, companyId) => {
  const url = userId ?
    `${ENDPOINT_DOCUMENT_SUBMISSIONS}?definitionId=${definitionId}&userId=${userId}&companyId=${companyId}` :
    `${ENDPOINT_DOCUMENT_SUBMISSIONS}?definitionId=${definitionId}&companyId=${companyId}`

  let formData = new FormData()
  formData.append('file', file)

  for (let key in values) {
    formData.append(key, values[key])
  }

  return fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    },
    body: formData
  })
    .then(res => res.json())
}
