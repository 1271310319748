import React from 'react'
import { Label, Input, FormGroup, Button, InputGroup, InputGroupAddon } from 'reactstrap'
import styles from './FormikInput.module.scss'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class MultiInput extends React.Component {
  static propTypes = {
    values: PropTypes.array.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  handleChange = (index, value) => {
    // change existing value.
    const values = [...this.props.values]
    values[index] = value
    this.props.onChange(values)
  }

  handleAdd = () => {
    this.props.onChange([...this.props.values, ''])
  }

  handleDelete = (index) => {
    const values = [...this.props.values]
    values.splice(index, 1)
    this.props.onChange(values)
  }

  render() {
    const values = this.props.values || []

    return (
      <FormGroup>
        <Label className="d-block">
          <div className={styles.label}>{this.props.label}</div>
        </Label>

        {values.map((val, index) =>
          <InputGroup key={index} className={classNames('mb-2')}>
            <Input
              value={val}
              onChange={e => this.handleChange(index, e.target.value)}
              placeholder="Ingrese un nuevo valor..."
            />

            <InputGroupAddon addonType="append">
              <Button color="danger" style={{ color: '#ffffff' }} onClick={() => this.handleDelete(index)}>
                Eliminar
              </Button>
            </InputGroupAddon>
          </InputGroup>
        )}

        <Button size="sm" onClick={() => this.handleAdd()}>+ Agregar actividad</Button>
      </FormGroup>
    )
  }
}
