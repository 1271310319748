import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Button } from 'reactstrap'
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap'
import { Formik } from 'formik'

import ButtonNavigation from '../components/ButtonNavigation/ButtonNavigation'
import FormikInputGenerator from '../components/Form/FormikInputGenerator'
import DashboardLayout from '../components/Layout/DashboardLayout'
import * as documentForm from '../forms/documentForm'
import { createDocumentDefinition, fetchDocumentDefinitions, updateDocumentDefinition } from '../requests/documents'
import ListTable from '../components/ListTable/ListTable'
import { UserContext } from '../contexts'
import { toast } from 'react-toastify'

class CompanyDocumentDefinitions extends React.Component {
  static contextType = UserContext

  static propTypes = {
    companyId: PropTypes.string.isRequired,
    documentType: PropTypes.string.isRequired,
  }

  state = {
    formModalOpen: false,
    documentDefinition: {},
    documentDefinitions: []
  }

  componentDidMount () {
    this.refreshDocumentDefinitions()
  }

  refreshDocumentDefinitions = () => {
    const { companyId, documentType } = this.props
    const { token } = this.context

    fetchDocumentDefinitions(token, companyId, documentType)
      .then(documentDefinitions => this.setState({ documentDefinitions }))
  }

  handleEdit = (documentDefinition) => {
    this.setState({ documentDefinition })
    this.toggleFormModal()
  }

  handleSubmit = (form, { setSubmitting }) => {
    const { companyId, documentType } = this.props
    const { token } = this.context
    const { documentDefinition } = this.state

    form = { ...form, type: documentType }

    if (documentDefinition._id) {
      updateDocumentDefinition(token, documentDefinition._id, form)
        .then(this.handleResult('Se ha actualizado el documento requerido', setSubmitting))
    } else {
      createDocumentDefinition(token, companyId, form)
        .then(this.handleResult('Se ha creado el documento requerido', setSubmitting))
    }
  }

  handleResult = (message, setSubmitting) => (res) => {
    setSubmitting(false)
    this.toggleFormModal()

    if (res.errors) {
      toast.error(res.message)
    } else {
      this.refreshDocumentDefinitions()
      toast.success(message)
    }
  }

  toggleFormModal = () => {
    this.setState({ formModalOpen: !this.state.formModalOpen })
  }

  render () {
    const { companyId } = this.props
    const { documentDefinitions } = this.state
    const { token } = this.context

    return (
      <DashboardLayout companyId={companyId} heading="Documentos Requeridos" token={token}>
        {this.renderNavigation()}
        <p/>

        <Button onClick={this.toggleFormModal} size="sm">+ Agregar Documento Requerido</Button>
        <p/>

        <ListTable data={documentDefinitions} onEdit={this.handleEdit} columns={[
          { key: 'name', display: 'Nombre Documento' },
          { key: 'code', display: 'Código' },
          { key: 'category', display: 'Categoría' },
          { key: 'dueDateNotification', display: 'Not. Vencimiento', render: this.renderDueDateNotification },
        ]}/>

        {this.renderFormModal()}
      </DashboardLayout>
    )
  }

  renderDueDateNotification (documentDefinition) {
    if (documentDefinition.dueDateNotification === 7) {
      return '1 Semana'
    } else if (documentDefinition.dueDateNotification === 14) {
      return '2 Semanas'
    } else if (documentDefinition.dueDateNotification === 30) {
      return '1 Mes'
    } else if (documentDefinition.dueDateNotification === undefined || documentDefinition.dueDateNotification === 0) {
      return `Desactivado`
    } else {
      return `${documentDefinition.dueDateNotification} Días`
    }
  }

  renderNavigation () {
    const { companyId } = this.props.match.params
    const { location } = this.props

    return (
      <ButtonNavigation links={[
        { to: `/companies/${companyId}/documentDefinitions`, text: 'Documentos Empresa' },
        { to: `/companies/${companyId}/documentDefinitions?type=user`, text: 'Documentos Usuario' },
      ]} location={location}/>
    )
  }

  renderFormModal () {
    const { formModalOpen, documentDefinition } = this.state

    return (
      <Modal isOpen={formModalOpen} toggle={this.toggleFormModal}>
        <ModalHeader toggle={this.toggleFormModal}>Documento Requerido</ModalHeader>

        <Formik
          onSubmit={this.handleSubmit}
          initialValues={documentDefinition}
          enableReinitialize={true}
          validationSchema={documentForm.validation}
        >
          {props =>
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <FormikInputGenerator fields={documentForm.fields} formikProps={props}/>
              </ModalBody>

              <ModalFooter>
                <Button color="danger" onClick={this.toggleFormModal}>Cerrar</Button>
                {' '}
                <Button color="success" type="submit" disabled={props.isSubmitting}>Guardar</Button>
              </ModalFooter>
            </form>
          }
        </Formik>
      </Modal>
    )
  }
}

export default class Wrapper extends React.Component {
  render () {
    const { type } = queryString.parse(this.props.location.search)
    const { companyId } = this.props.match.params

    return (
      <CompanyDocumentDefinitions {...this.props} companyId={companyId} documentType={type ? type : 'company'} key={type}/>
    )
  }
}
