import React from 'react'
import styles from './BoxInlineListItem.module.scss'

export default class BoxInlineListItem extends React.Component {
  render () {
    return (
      <div className={styles.container}>
        {this.props.children}
      </div>
    )
  }
}
