import React from 'react'
import PropTypes from 'prop-types'
import BoxInlineListItem from './BoxInlineListItem'

export default class BoxInlineList extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    element: PropTypes.any.isRequired,
  }

  render () {
    const { data, element: Element } = this.props

    return (
      <div>
        {data.map((item, index) =>
          <BoxInlineListItem key={index}>
            <Element instance={item}/>
            <div style={{clear: 'both'}}/>
          </BoxInlineListItem>
        )}
      </div>
    )
  }
}
