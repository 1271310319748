import React from 'react'
import styles from './BoxBody.module.scss'

export default class BoxBody extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        {this.props.children}
      </div>
    )
  }
}