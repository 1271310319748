import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Box from '../components/Box/Box'
import BoxBody from '../components/Box/BoxBody'
import FormikInput from '../components/Form/FormikInput'
import { Button } from 'reactstrap'
import { toast } from 'react-toastify'
import MinimalLayout from '../components/Layout/MinimalLayout'
import { UserContext } from '../contexts'
import { updateUserPassword } from '../requests/users'

export default class UserForm extends React.Component {
  static contextType = UserContext

  state = {
    user: {},
    positions: [],
    isKeyUser: false,
    canAdmin: false,
  }

  componentDidMount () {
  }

  handleSubmit = (form, { setSubmitting }) => {
    const { userId } = this.props.match.params
    const { token } = this.context
    const { history } = this.props

    updateUserPassword(token, userId, form)
      .then(res => {
        setSubmitting(false)

        if (this.handleIfError(res)) {
          history.push(`/users/companies`)
        }
      })
  }

  handleIfError = (res) => {
    if (res.errors) {
      toast.error(res.message)
      return false
    } else {
      return true
    }
  }

  render () {
    return (
      <MinimalLayout>
        <Box title="Cambiar contraseña" subtext="Rellene los campos para cambiar la contraseña del usuario">
          <BoxBody>
            <Formik
              onSubmit={this.handleSubmit}
              enableReinitialize={true}
              initialValues={this.state.user}
              validationSchema={Yup.object().shape({
                password: Yup.string().required('Requerido'),
                password2: Yup.string().required('Requerido'),
              })}
            >
              {props =>
                <form onSubmit={props.handleSubmit}>
                  <FormikInput formikProps={props} label="Nueva contraseña" name="password" type="password"/>
                  <FormikInput formikProps={props} label="Repetir nueva contraseña" name="password2" type="password"/>

                  <div className="text-center mt-5">
                    <Button type="submit" disabled={props.isSubmitting}>Cambiar</Button>
                  </div>
                </form>
              }
            </Formik>
          </BoxBody>
        </Box>
      </MinimalLayout>
    )
  }
}
