import PropTypes from 'prop-types'
import React from 'react'
import { toast } from 'react-toastify'
import DashboardLayout from '../components/Layout/DashboardLayout'
import ListTable from '../components/ListTable/ListTable'
import UserNavigation from '../components/UserNavigation/UserNavigation'
import queryString from 'query-string'
import { Button, ModalHeader, Modal, ModalBody, ModalFooter, Badge } from 'reactstrap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import FormikInput from '../components/Form/FormikInput'
import { UserContext } from '../contexts'
import { fetchIsAdmin } from '../requests/companies'
import { createDocumentSubmission, fetchDocumentDefinitions, fetchDocumentSubmission } from '../requests/documents'
import { toUTCDateString } from '../utils/dateUtils'

class UserDocuments extends React.Component {
  static contextType = UserContext

  static propTypes = {
    companyId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
  }

  state = {
    formModalOpen: false,
    documentSubmission: {},
    userDefinitions: [],
    companyDefinitions: [],
    canAdmin: false,
  }

  componentDidMount () {
    this.refreshDocumentDefinitions()
    this.refreshCanAdmin()
  }

  refreshDocumentDefinitions = async () => {
    const { token } = this.context
    const { companyId } = this.props

    return fetchDocumentDefinitions(token, companyId)
      .then(async definitions => {
        const userDefinitions = []
        const companyDefinitions = []

        for (let definition of definitions) {
          const modDefinition = await this.fetchSubmission(definition)

          if (modDefinition.type === 'user') {
            userDefinitions.push(modDefinition)
          } else if (modDefinition.type === 'company') {
            companyDefinitions.push(modDefinition)
          }
        }

        this.setState({
          userDefinitions,
          companyDefinitions
        })
      })
  }

  refreshCanAdmin = () => {
    const { companyId } = this.props
    const { token, bundle } = this.context

    if (bundle.isAdmin) {
      this.setState({ canAdmin: bundle.isAdmin })
    } else {
      fetchIsAdmin(token, companyId, bundle._id)
        .then(canAdmin => this.setState({ canAdmin }))
    }
  }

  fetchSubmission = async (definition) => {
    const { token } = this.context
    const { userId, companyId } = this.props
    const definitionId = definition._id

    const submission = await fetchDocumentSubmission(token, userId, definitionId, companyId)

    let expirationDate = submission.expirationDate
    if (expirationDate) {
      expirationDate = toUTCDateString(submission.expirationDate)
    } else {
      expirationDate = 'Sin vencimiento'
    }

    let state = <Badge color="primary">Vigente</Badge>
    if (submission.error) {
      state = <Badge color="warning">Sin cargar</Badge>
    } else {
      const currentDate = Date.now()
      const submissionDate = new Date(submission.expirationDate)

      if (currentDate > submissionDate) {
        state = <Badge color="danger">Vencido</Badge>
      }
    }

    return {
      ...definition,
      state,
      expirationDate,
      url: `/documents/${submission.file}`,
    }
  }

  handleEdit = (row) => {
    this.setState({
      formModalOpen: true,
      documentSubmission: {
        definitionId: row._id,
      }
    })
  }

  toggleFormModal = () => {
    this.setState({ formModalOpen: !this.state.formModalOpen })
  }

  handleFileChange = (e) => {
    if (!e.target.files) {
      return
    }

    let file = e.target.files[0]

    this.setState({ file })
  }

  handleSubmit = (form, { setSubmitting }) => {
    const { userId, companyId } = this.props
    const { token } = this.context
    const { definitionId } = form
    const { file } = this.state

    createDocumentSubmission(token, userId, definitionId, file, form, companyId)
      .then(this.handleResult('Se ha actualizado el documento', setSubmitting))
  }

  handleResult = (message, setSubmitting) => (res) => {
    setSubmitting(false)
    this.toggleFormModal()

    if (res.errors) {
      toast.error(res.message)
    } else {
      this.refreshDocumentDefinitions()
      toast.success(message)
    }
  }

  render () {
    const { userId, companyId } = this.props
    const { userDefinitions, companyDefinitions, canAdmin } = this.state
    const { token } = this.context

    return (
      <DashboardLayout companyId={companyId} heading="Documentos" token={token}>
        <UserNavigation {...this.props} companyId={companyId} userId={userId} canAdmin={canAdmin}/>
        <p/>

        <h4>Documentos Usuario</h4>
        <ListTable data={userDefinitions} onEdit={this.handleEdit} columns={[
          { key: 'name', display: 'Nombre del Documento', linkTo: row => row.url, asHref: true },
          { key: 'code', display: 'Código' },
          { key: 'category', display: 'Categoría' },
          { key: 'expirationDate', display: 'Fecha de Vencimiento' },
          { key: 'state', display: 'Estado' },
        ]}/>

        <h4>Documentos Empresa</h4>
        <ListTable data={companyDefinitions} onEdit={this.handleEdit} columns={[
          { key: 'name', display: 'Nombre del Documento', linkTo: row => row.url, asHref: true },
          { key: 'code', display: 'Código' },
          { key: 'category', display: 'Categoría' },
          { key: 'expirationDate', display: 'Fecha de Vencimiento' },
          { key: 'state', display: 'Estado' },
        ]}/>

        {this.renderAddDocument()}
      </DashboardLayout>
    )
  }

  renderAddDocument () {
    const { documentSubmission } = this.state

    return (
      <Modal isOpen={this.state.formModalOpen} toggle={this.toggleFormModal}>
        <ModalHeader toggle={this.toggleFormModal}>Agregar Documento</ModalHeader>

        <Formik
          onSubmit={this.handleSubmit}
          initialValues={documentSubmission}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            definitionId: Yup.string().required('Required'),
            expirationDate: Yup.date(),
          })}
        >
          {props =>
            <form onSubmit={props.handleSubmit}>
              <ModalBody>
                <FormikInput formikProps={props} label="Nombre del Documento" name="definitionId" type="hidden"/>
                <input type="file" onChange={this.handleFileChange} name="file"/>
                <FormikInput formikProps={props} label="Fecha de Vencimiento" name="expirationDate" type="date"/>
              </ModalBody>

              <ModalFooter>
                <Button color="danger" onClick={this.toggleFormModal}>Cerrar</Button>
                {' '}
                <Button color="success" type="submit" disabled={props.isSubmitting}>Guardar</Button>
              </ModalFooter>
            </form>
          }
        </Formik>
      </Modal>
    )
  }
}

export default class Wrapper extends React.Component {
  render () {
    const { companyId } = queryString.parse(this.props.location.search)
    const { userId } = this.props.match.params

    return (
      <UserDocuments {...this.props} companyId={companyId} userId={userId}/>
    )
  }
}
