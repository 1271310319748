import { NavLink } from 'react-router-dom'
import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

export default class ButtonNavigation extends React.Component {
  static propTypes = {
    links: PropTypes.arrayOf(PropTypes.object).isRequired,
    location: PropTypes.any.isRequired,
  }

  render () {
    const { links, location } = this.props
    const href = location.pathname + location.search

    return (
      <div>
        {links.map(({ to, text }) => <Link key={to} to={to} href={href}>{text}</Link>)}
      </div>
    )
  }
}

const Link = ({ to, href, children }) => {
  return (
    <NavLink
      to={to}
      className={classNames('btn', 'btn-sm', 'mr-2', to === href ? 'btn-primary' : 'btn-secondary')}
    >
      {children}
    </NavLink>
  )
}
