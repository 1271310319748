import * as Yup from 'yup'

export const fields = [
  { label: 'Nombre', name: 'name' },
  { label: 'Código', name: 'code' },
  { label: 'Categoría', name: 'category' },
  { label: 'Notificación Vencimiento', name: 'dueDateNotification', options: [
      { value: '0', display: 'Sin notificación' },
      { value: '7', display: '1 Semana' },
      { value: '14', display: '2 Semanas' },
      { value: '30', display: '1 Mes' },
    ]},
]

export const validation = Yup.object().shape({
  name: Yup.string().required('Required'),
  code: Yup.string().required('Required'),
  category: Yup.string().required('Required'),
  dueDateNotification: Yup.string()
})
