import { ENDPOINT_SII } from '../config'

export const fetchSiiRecord = (rut) => {
  return fetch(`${ENDPOINT_SII}/v2/info/${rut}`, {
    method: 'GET',
    headers: {
      'Content-Type': `application/json`,
    }
  }).then(res => res.json())
}
